import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    HttpClientModule
  ],
  template: '<div style="overflow: hidden; touch-action: manipulation; "><router-outlet></router-outlet></div>',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'topik';
  
}
