<div class="container-report">
    <div class="classic-exam">
        <div class="title">
            <div class="left">
                Đề thi tiêu chuẩn
            </div>
            <div class="right">
                <img src="assets/common/info-icon-gray.svg" alt="">
            </div>
        </div>
        <div class="list-item">
            <div class="item">
                <div class="icon">
                    <img src="assets/right_side/point-icon.svg" alt="">
                </div>
                <div class="info">
                    <div class="name">
                        Điểm thi
                    </div>
                    <div class="value">
                        <span>{{dataFullTest?.totalScore || 0}}</span>/{{dataFullTest?.scoreConfig || 0}}
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="icon">
                    <img src="assets/right_side/level-icon.svg" alt="">
                </div>
                <div class="info">
                    <div class="name">
                        Trình độ
                    </div>
                    <div class="value">
                        <span>{{level || 0}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="skill-exam">
        <div class="title">
            <div class="left">
                Đề thi kỹ năng
            </div>
            <div class="right">
                <img src="assets/common/info-icon-gray.svg" alt="">
            </div>
        </div>
        <div class="list-item">
            <div class="item">
                <div class="left">
                    <div class="icon">
                        <img src="assets/right_side/listening-icon.svg" alt="">
                    </div>
                    <div class="name">
                        Nghe
                    </div>
                </div>
                <div class="value">
                    <span>40</span>/ 100
                </div>
            </div>
            <div class="item">
                <div class="left">
                    <div class="icon">
                        <img src="assets/right_side/writing-icon.svg" alt="">
                    </div>
                    <div class="name">
                        Viết
                    </div>
                </div>
                <div class="value">
                    <span>0</span>/ 0
                </div>
            </div>
            <div class="item">
                <div class="left">
                    <div class="icon">
                        <img src="assets/right_side/reading-icon.svg" alt="">
                    </div>
                    <div class="name">
                        Đọc
                    </div>
                </div>
                <div class="value">
                    <span>56</span>/ 100
                </div>
            </div>
    
        </div>
    </div> -->
    <div class="strick" *ngIf="showStreak">
        <div class="icon">
            <img src="assets/right_side/streak-icon.svg" alt="">
            <div class="title">
                <div class="name">
                    Đã luyện thi <span> {{totalStreak}} ngày</span>
                </div>
                <div class="sub-name">
                    Luyện thi mỗi ngày để tiến bộ hơn!
                </div>
            </div>
        </div>
        <div class="week">
            <div class="day" *ngFor="let day of dayWithTimeStamp" [class.active-day]="day.isDone">{{day.name}}</div>
        </div>
        <div class="right">
            <img src="assets/common/info-icon-gray.svg" alt="">
        </div>
    </div>
</div>