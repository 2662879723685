import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenshotService {
  private screenShot = new Subject<{open: boolean, callback?: Function}>();
  listenScreenShot = this.screenShot.asObservable();
  constructor() { }
  openScreenShot(isOpen: {open: boolean, callback?: Function}) {
    this.screenShot.next(isOpen);
  }
}
