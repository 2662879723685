import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { OAuthService } from '../../../auth/auth.service';
import { AvatarModule } from 'ngx-avatars';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs';
import { ProfileService } from '../../../components/profile/profile.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, AvatarModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  userInfo: any;
  isBackBtn = false;
  userName: any;
  backUrl: any = '';
  constructor(
    private oAuthService: OAuthService,
    private router: Router,
    private _location: Location,
    private ProfileService: ProfileService,
    private activatedRoute: ActivatedRoute
  ) {}
  ngOnInit() {
    this.ProfileService.profileInfo$.subscribe((res: any) => {
      if (res) {
        this.userName = res.display_name;
      }
    });
    this.router.url === '/'
      ? (this.isBackBtn = false)
      : (this.isBackBtn = true);

    this.userInfo = this.oAuthService.getUserInfo();
    this.userName = this.userInfo.DisplayName;
    this.checkDataRouter();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.router.url === '/'
          ? (this.isBackBtn = false)
          : (this.isBackBtn = true);

        this.checkDataRouter();
      });
  }

  goToProfile() {
    this.router.navigate(['/profile']);
  }
  back() {
    this.router.navigate([this.backUrl]);
  }

  checkDataRouter() {
    this.backUrl = '';
    let route1 = this.activatedRoute.firstChild;
    let child1 = route1;
    let data = child1?.snapshot?.data || {};
    while (child1) {
      if (child1.firstChild) {
        child1 = child1.firstChild;
        route1 = child1;
        data = { ...data, ...child1.snapshot?.data };
      } else {
        child1 = null;
      }
    }
    data['backUrl'] ? this.backUrl = data['backUrl'] : '';
  }
}
