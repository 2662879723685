<div class="container-report">
    <div class="pie-chart">
        <app-report-pie>

        </app-report-pie>
    </div>
    @if (dayWithTimeStamp?.length) {
    <div class="strick">
        <div class="icon">
            <img src="assets/right_side/streak-learn-icon.svg" alt="">
            <div class="title">
                <div class="name">
                    Đã học <span> {{totalStreak}} ngày</span>
                </div>
                <div class="sub-name">
                    Cùng học ít nhất một bài mới mỗi ngày nhé!
                </div>
            </div>
        </div>
        <div class="week">
            <div class="day" *ngFor="let day of dayWithTimeStamp" [class.active-day]="day.isDone">{{day.name}}</div>
        </div>
    </div>
    }

</div>