import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LoginGoogleComponent } from './login-google/login-google.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OAuthService } from '../../auth/auth.service';
import { MixpanelService } from '../../common/mixpanel/mixpanel.service';
import { MIXPANEL } from '../../common/mixpanel/mixpanel.constant';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    LoginGoogleComponent
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  queryParamsObj: any = {};
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private oAuthService: OAuthService,
    private mixpanelService: MixpanelService

  ) {

  }
  ngOnInit() {
    this.oAuthService.logOut();
    this.queryParamsObj = this.activatedRoute.snapshot.queryParams;
    if (this.queryParamsObj['access_token']) {
      // this.mixpanelService.track(MIXPANEL.SIGN_UP.name, {
      //   action: MIXPANEL.SIGN_UP.actions.LOGIN_SUCCESS,
      // });
      this.oAuthService.setToken(this.queryParamsObj['access_token']);
      const userInfo = this.oAuthService.getUserInfo();
      this.mixpanelService.updateMixpanel(userInfo);
      if (this.queryParamsObj && this.queryParamsObj['back']) {
        window.location.href = this.queryParamsObj['back'];
      } else {
        this.router.navigate(['/']);
      }
    }
    if (this.queryParamsObj && this.queryParamsObj['message']) {
      if (this.queryParamsObj && +this.queryParamsObj['status'] === 200) {
          this.toastrService.success(this.queryParamsObj['message'])
      } else {
          this.toastrService.warning(this.queryParamsObj['message'])
      }
    }
  }

}
