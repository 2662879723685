import * as env from './../environments/environment';

export const environment = {
  THANH_TOAN: env.thanh_toan,
  BASE_API_LOG_DEV: env.base_api_log_dev,
  BASE_API_URL: env.base_api_url,
  AUTH_SERVER: env.base_auth_server,
  BASE_API_HUNT_QUIZZ: env.base_api_hunt_quizz,
  BASE_API_GAME: env.base_api_game,
  OPEN_APP: env.open_app,
  CAPTCHA_KEY: env.captcha_key
}; 

export const CONSTANTS = {
  CODE_APP: 'TOPIK',
  OAUTH: {
    GOOGLE: 'topik/sso/google/uri-login',
    PHONE: 'api/account/profile/phone',
    PROFILE: 'api/account/profile/update',
    REFRESH_TOKEN: 'topik/account/refresh',
    UPDATE_MIXPANEL: 'api/user/update/mixpanel',
  },
  QRCODE: 'api/qrcode/info/',
  API: {
    STEAK: {
      GET_STREAK: 'api/user-streak',
      PUT_STREAK: 'api/user-streak'
    },
    EXAM: {
      DETAIL: 'topik/topik-exam/detail/doing/',
      GROUPS_COURSE: 'topik/topik-exam/course/groups',
      GROUP_DETAIL: 'topik/topik-exam/group/',
      START: 'topik/topik-exam/start/',
      DONE_SECTION: 'topik/topik-exam/done/section/',
      DETAIL_TYPE_SECTION: 'topik/topik-exam/detail/doing/',
      DONE: 'topik/topik-exam/done/',
      DETAIL_EXAM: 'topik/topik-exam/detail/',
      INFO_EXAM: 'topik/topik-exam/info/',
     
    },
    REPORT: {
      HISTORY: 'topik/report/history',
      CHART_COURSE: 'topik/report/learn/chart-course-current',
      GET_REPORT: 'topik/report'
    },
    COURSE: {
      LIST_COURSE: 'topik/topik-course/course',
      COURSE_DETAIL: 'topik/topik-course/course/',
      GET_UNIT: 'topik/topik-course/unit/',
      POST_CHANGE_COURSE: 'topik/topik-course/course-change/',
      GET_LESSON_DETAIL: 'topik/topik-course/lesson/',
      GET_CONTENT_MEDIA: 'api/content-media/',
      GET_CONTENT_TEXTBOOK: 'textbook/api/textbook/content/',
      START_LESSON: 'topik/topik-course/lesson/start',
      FINISH_LESSON: 'topik/topik-course/lesson/finish'

  },
  PRACTICE_CORE: {
    INFO: 'api/practice/info/',
    START: 'api/practice/start/',
    STEP_DATA: 'api/practice/questions/detail/',
    SEND_ANSWER: 'api/practice/questions/sendanswer/',
    STEP_ANSWER: 'api/practice/step/'
},
  PRACTICE: {
    GET_GROUP_BY_COURSE: 'topik/topik-practice/course/groups',
    GET_INFO_TYPE_PRACTICE: 'topik/topik-practice/group-type-practice/',
    GET_GROUP: 'topik/topik-practice/course/groups',
    GET_BY_GROUP: 'topik/topik-practice/group/',
    GET_BY_SUB_GROUP: 'topik/topik-practice/sub-group/',
    GET_PRACTICE_BY_ID: 'topik/topik-practice/practice/',
    GET_PRACTICE_CONFIG: 'topik/topik-practice/config/',

    GET_PRACTICE_DETAIL_BY_ID: 'topik/topik-practice/detail/',
    POST_START: 'topik/topik-practice/start/',
    PUT_SUBMIT: 'topik/topik-practice/submit',
    GET_DETAIL_DONE: 'topik/topik-practice/detail/done/',
    GET_LIST_PART: 'topik/topik-practice/part/',
    GET_LIST_TOPIC: 'topik/topik-practice/topic',
    GET_LIST_QUESTION_TYPE: 'topik/topik-practice/questionType/',
    GET_CURRENT_PRACTICE: 'topik/topik-practice/current',
    GET_DETAIL_QUESTION: 'topik/topik-practice/detail/question/',

    IELTS: {
        PRACTICE: {
            GET_LIST: 'api/ielts/practice/listPractice/',
            GET_LIST_SUMMIT: 'api/ielts/partners/summit/practice/',
            IELTS_CONFIG: 'api/ielts/practice/config/',
            IELTS_DETAIL: 'api/ielts/practice/detail/',
            GET_LIST_PART: 'api/ielts/practice/part/',
            GET_LIST_TOPIC: 'api/ielts/practice/topic/',
            GET_LIST_VOCBULARY_TOPIC: 'api/ielts/practice/topic-vocabulary',
            GET_LIST_QUESTION_TYPE: 'api/ielts/practice/questionType/',
            SPEAKING: {
                PRESIGNED_URL: 'topik/topik-practice/answer/pre-signed-url',
                SUBMIT_QUESTION: 'topik/topik-practice/submit-question',
                IMPROVEMENT: 'topik/topik-practice/detail/improvement/'
            },
            WRITING_AI: {
                CHECK_GRAMMAR: 'virtual-writing/check-grammar',
                UPDATE_DATA_GRAMMAR: '/ielts/virtual-writing/update-data-grammar',
                UPDATE_ANSWER: '/ielts/virtual-writing/update-answer'
            }

        },
    },
},
  },
};


