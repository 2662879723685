import { Component, HostListener, OnInit } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CommonModule } from '@angular/common';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,

} from '@angular/router';
import { RightSideComponent } from './right-side/right-side.component';
import { filter } from 'rxjs';
import { LoadingServiceComponent } from '../../common/loading-service/loading-service.component';
import { MODAL_ID } from '../../common/popup/modal-common/modal-id.const';
import { ModalCommonComponent } from '../../common/popup/modal-common/modal-common.component';
import { ConfirmModalComponent } from '../../common/popup/confirm-modal/confirm-modal.component';
import { SendErrorComponent } from '../../common/popup/send-error/send-error.component';
import { ScreenshotComponent } from '../../common/popup/screenshot/screenshot.component';
import { PopupOpenbookComponent } from './sidebar/popup-openbook/popup-openbook.component';
import { PopupGetStreakComponent } from '../../common/popup/popup-get-streak/popup-get-streak.component';

@Component({
  selector: 'app-full-layout',
  standalone: true,
  imports: [
    HeaderComponent,
    SidebarComponent,
    CommonModule,
    RouterOutlet,
    RightSideComponent,
    LoadingServiceComponent,
    ModalCommonComponent,
    ConfirmModalComponent,
    SendErrorComponent,
    ScreenshotComponent,
    PopupOpenbookComponent,
    PopupGetStreakComponent
  ],
  templateUrl: './full-layout.component.html',
  styleUrl: './full-layout.component.scss',
})
export class FullLayoutComponent implements OnInit {
  isRightSide = true;
  fullScreen = false;
  idConfirm = MODAL_ID.COMMON.CONFIRM;
  idModalSendError = MODAL_ID.COMMON.SEND_ERROR;
  idOpenBook = MODAL_ID.COMMON.OPENBOOK ;
  idGetStreak = MODAL_ID.COMMON.GET_STEAK;
  screenWidth: any;
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.screenWidth = window.innerWidth;
  }
  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.checkDataRouter();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.checkDataRouter();
      });
  }
  checkDataRouter() {
    let route1 = this.activatedRoute.firstChild;
    let child1 = route1;
    let data = child1?.snapshot?.data || {};
    while (child1) {
      if (child1.firstChild) {
        child1 = child1.firstChild;
        route1 = child1;
        data = { ...data, ...child1.snapshot?.data };
      } else {
        child1 = null;
      }
    }
    this.isRightSide = true;
    this.fullScreen = false;
    data['fullScreen'] ? this.fullScreen = data['fullScreen'] : false;
    data['isRightSide'] === false ? this.isRightSide = data['isRightSide'] : true;
  }
}
