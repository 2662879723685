<div class="container-login">
    <div class="content-login">
        <div class="logo">
            <img src="assets/common/logo.svg" alt="">
        </div>
        <div class="title">
            Chinh phục TOPIK
            cùng Edmicro
        </div>
        <div class="des">
            Mở khóa các công cụ học tập đa dạng, nhận phản hồi tức thì từ AI và tiếp cận nguồn tài liệu phong phú. Để
            bắt đầu học tập, hãy tiếp tục đăng ký / đăng nhập bằng Google.
        </div>
        <div class="btn">
            <button (click)="loginGoogle()">
                <div class="img-btn">
                    <img src="assets/login/google-icon.svg" alt="">
                </div>
                <div class="text-btn">
                    TIẾP TỤC VỚI GOOGLE
                </div>
            </button>
        </div>
    </div>
    <div class="info">
        <div>Hotline: 096 960 2660</div>
        <div>Email: {{email}}</div>
        <a href="https://edmicrohelp.notion.site/H-ng-d-n-v-H-tr-4f0f13594c5a4f3b9fc9899e49efb446" target="_blank"
            style="text-decoration: none; color: #5685FF;">Xem hướng dẫn sử dụng</a>
    </div>

</div>