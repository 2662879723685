import { Component, ViewChild, } from '@angular/core';
import { UntypedFormGroup, Validators, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { OAuthService } from '../../../auth/auth.service';
import { SendErrorService } from './send-error.service';
import { ScreenshotService } from '../screenshot/screenshot.service';
import { ModalCommonService } from '../modal-common/modal-common.service';
import { MODAL_ID } from '../modal-common/modal-id.const';
import { ReportData } from './send-error';
import { ToastrService } from 'ngx-toastr';
import { CONSTANTS } from '../../../constants/constants';
import { CommonModule } from '@angular/common';
import { ScreenshotComponent } from '../screenshot/screenshot.component';

@Component({
  selector: 'app-send-error',
  standalone: true,
  imports: [CommonModule,
    ScreenshotComponent,
    FormsModule,
    ReactiveFormsModule,],

  templateUrl: './send-error.component.html',
  styleUrls: ['./send-error.component.scss']
})
export class SendErrorComponent {
  // @ViewChild('pauseModal') pauseModal: ModalDirective;
  reportId: any;
  isModalShown = false;
  reportData = new ReportData();
  sending!: boolean;
  reportForm!: UntypedFormGroup;
  reponseText!: string;
  selectedItem = 0;
  subjectId!: string;
  gradeId: any;
  userInfo: any = {};
  imgScreenShot: any;
  isScreenShot: any;
  imgScreenShotDisplay: any;
  question: any;
  idSendError = MODAL_ID.COMMON.SEND_ERROR
  // fileInput: String = '';
  constructor(
    private sendErrorService: SendErrorService,
    private oAuthService: OAuthService,
    private screenShot: ScreenshotService,
    private sanitizer: DomSanitizer,
    private modalService: ModalCommonService,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.userInfo = this.oAuthService.getUserInfo();
    this.gradeId = this.userInfo.GradeId;
    this.reponseText = '';
    this.sending = false;
    this.modalService.getModalData(this.idSendError).subscribe(((res: any) => {
      if (res.data?.dataStandard?.numberQuestion) {
        this.reportId = res.data?.dataStandard?.numberQuestion;
      } else if (res.data?.reportId) {
        this.reportId = res.data.reportId
      } else if (res.data) {
        this.reportId = res.data
      }
      this.question = res.data;
      if (this.question?.img) {
        this.imgScreenShot = this.question.img;
        this.imgScreenShotDisplay = this.sanitizer.bypassSecurityTrustResourceUrl(this.imgScreenShot);
      }
      this.creatForm();

    }))
    // this.reportForm.patchValue({
    //   errorType: 0
    // });
  }
  get errorType() { return this.reportForm }
  get contentReport() { return this.reportForm.get('contentReport'); }
  // get file() { return this.reportForm.get('file'); }

  creatForm() {
    this.reportForm = new FormGroup({
      'errorType': new FormControl(0, [
        Validators.required,
        Validators.min(1)
      ]),
      'contentReport': new FormControl(this.question?.contentReport, [
        Validators.required,
      ]),
    });
  }

  b64toBlob(b64Data: any, sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: 'image/png' });
    return blob;
  }

  report(form: UntypedFormGroup) {
    this.sending = true; // disable submit button again
    this.reportData.numberQuestion = this.reportId;
    this.reportData.contentReport = form.value.contentReport;
    this.reportData.error = form.value.errorType;
    this.reportData.gradeId = this.gradeId;
    this.reportData.subjectId = this.subjectId;
    this.submit();
  }

  capture() {

    this.isModalShown = false;
    this.isScreenShot = true;
    this.modalService.closeModal(MODAL_ID.COMMON.SEND_ERROR);
    setTimeout(() => {
      const reportForm = this.reportForm.value;
      this.screenShot.openScreenShot({
        open: true, callback: (img: any) => {
          this.modalService.openModal(MODAL_ID.COMMON.SEND_ERROR, {
            img: img,
            reportForm: reportForm,
            reportId: this.reportId,
            ...this.question
          });
          this.imgScreenShot = img;
          this.imgScreenShotDisplay = this.sanitizer.bypassSecurityTrustResourceUrl(img);
          this.isScreenShot = false;
        }
      });

    }, 1000);
  }

  onHidden() {
    // !!! Important: Only destroy the modal when it is finished being hidden from user
    // otherwise the browser will return error at ` this._element.nativeElement.parentElement.querySelectorAll('.in[bsModal]');`
    this.isModalShown = false;
    this.imgScreenShot = null;
  }
  removeImg() {
    this.imgScreenShot = null;
    this.imgScreenShotDisplay = null;
  }
  submit() {
    const param = {
      image: this.imgScreenShotDisplay?.changingThisBreaksApplicationSecurity,
      errorType: this.reportData.error,
      platform: 3,
      content: this.reportData.contentReport,
      link: "",
      contentId: this.reportId.toString(),
      codeApp: CONSTANTS.CODE_APP
    }
    this.sendErrorService.postQuestionReport(param).subscribe(res => {
      if (res) {
        this.toastrService.success('Báo lỗi thành công')
      }
      this.modalService.closeModal(MODAL_ID.COMMON.SEND_ERROR);
    });
  }
  close() {
    this.modalService.closeModal(this.idSendError)
  }
}
