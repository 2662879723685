import { Component, OnInit, ElementRef, AfterViewInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import html2canvas from 'html2canvas';
import { ScreenshotService } from './screenshot.service';
import { fromEvent, Subscription, merge, combineLatest, of } from 'rxjs';
import { catchError, last, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-screenshot',
  standalone: true, 
  imports:  [CommonModule],
  templateUrl: './screenshot.component.html',
  styleUrls: ['./screenshot.component.scss']
})
export class ScreenshotComponent implements OnInit, AfterViewInit {
  crossHairsLeft = 0;
  crossHairsTop = 0;
  startX = 0;
  startY = 0;
  isMouseDown = false;
  isDraggingMouse = false;
  isDragging = false;
  borderWidth = '';
  tookScreenShot : any;
  croppedImageWidth : any;
  croppedImageHeight : any;
  imageUrl : any;
  isScreenShot = false;
  subcriptionMove = new Subscription();
  isConfirm : any;
  callback : any;
  @Output() screenShot = new EventEmitter();
  constructor(private screenShotService: ScreenshotService, private renderer: Renderer2,
    private elm: ElementRef) { }
  ngOnInit(): void {

  }
  checkMobile() {
    const userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
      return true;
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
      return true;

    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return false;
  }

  ngAfterViewInit() {
    this.screenShotService.listenScreenShot.subscribe((data: { open: boolean, callback?: Function }): void => {

      this.isScreenShot = data.open;      
      if (!data.open) {
        if (this.subcriptionMove) {
          this.subcriptionMove.unsubscribe();
        }
     
        this.isMouseDown = false;        
        return;
      }
      this.callback = data?.callback;

      if (this.checkMobile()) {
        this.screenShotService.openScreenShot({ open: false, callback: () => { } });
        html2canvas(document.querySelector('body')!, {
          scale: 1,
          useCORS: true,
          allowTaint: true,
        }).then((canvas) => {
          const croppedCanvas = document.createElement('canvas'),
            croppedCanvasContext = croppedCanvas.getContext('2d');

          croppedCanvas.width = window.innerWidth;
          croppedCanvas.height = window.innerHeight;
          const startX = window.scrollX;
          const startY = window.scrollY;
          croppedCanvasContext!.drawImage(canvas, startX, startY, window.innerWidth, window.innerHeight, 0, 0, window.innerWidth, window.innerHeight);
          this.imageUrl = croppedCanvas.toDataURL();
          this.callback(this.imageUrl);
        });
      } else {
        this.screenShotEvent()
      }

    })
  }
  screenShotEvent() {
    if (this.isScreenShot) {
      if (!this.checkMobile()) {
        setTimeout(() => {
          this.cropElement(this.elm.nativeElement.querySelector('#screenshot'));

        })
      }
    }
  }
  cropElement(element : any) {

    const mouseMove$ = merge(
      fromEvent(element, 'mousemove')
        .pipe(map((e) => { return e; })),
      fromEvent(element, 'touchmove')
        .pipe(map((e: any) => {

          return e.touches[0]
        })));
    const mouseUp$ = merge(
      fromEvent(window, 'mouseup'),

      fromEvent(window, 'touchend')
    );
    const mouseDown$ = merge(
      fromEvent(element, 'mousedown'),
      fromEvent(element, 'touchstart').pipe(map((e: any) => {
        this.isMouseDown = true;
        
        const rect = e.target.getBoundingClientRect();
        e.offsetX = e.touches[0].pageX - rect.left;
        e.pageX = e.touches[0].pageX;
        e.pageY = e.touches[0].pageY;

        e.offsetY = e.touches[0].pageY - rect.top;
        return e;
      })));


    const dragStart$ = mouseDown$;
    const dragMove$ = dragStart$.pipe(
      switchMap((start: any) =>
        mouseMove$.pipe(

          map((moveEvent: any) => ({
            ...moveEvent,
            pageX: moveEvent.pageX,
            pageY: moveEvent.pageY,

            startOffsetX: start.offsetX,
            startOffsetY: start.offsetY
          })),

          takeUntil(mouseUp$)
        )
      )
    );
    const dragEnd$ = dragStart$.pipe(
      switchMap((start: any) =>
        mouseMove$.pipe(
          map((moveEvent: any) => ({
            ...moveEvent,
            pageX: moveEvent.pageX,
            pageY: moveEvent.pageY,

            startOffsetX: start.offsetX,
            startOffsetY: start.offsetY
          })),
          takeUntil(mouseUp$),
          last(),
        )
      )
    );

    (combineLatest([
      dragStart$.pipe(
        tap(event => {
          this.isMouseDown = true;
          element.dispatchEvent(
            new CustomEvent('mydragstart', { detail: event })
          );
        })
      ),
      // dragMove$.pipe(
      //   tap(event => {
      //     console.log(event);

      //     element.dispatchEvent(new CustomEvent('mydragmove', { detail: event }));
      //   })
      // ),
      dragEnd$.pipe(
        catchError(()=>{    
          this.isMouseDown = false;

          this.borderWidth = '0'; // resetting the overlay
    
          if (this.isDragging) {
            // Don't take the screenshot unless the mouse moved somehow.
            this.tookScreenShot = true;
          }
    
          this.isDragging = false; 
          this.takeScreenshot();
     
          return of()
        }),
        tap(event => {
          element.dispatchEvent(new CustomEvent('mydragend', { detail: event }));
        })
      )
    ]).subscribe(() => {
      
      this.isMouseDown = false;

      this.borderWidth = '0'; // resetting the overlay

      if (this.isDragging) {
        // Don't take the screenshot unless the mouse moved somehow.
        this.tookScreenShot = true;
      }

      this.isDragging = false;

      this.takeScreenshot();
    }));

    ((dragMove$.subscribe(move => {

      if (this.isMouseDown) {
        this.isDragging = true;
        const startY = move.startOffsetY,
          startX = move.startOffsetX,
          endX = move.pageX,
          endY = move.pageY,
          windowWidth = window.innerWidth,
          windowHeight = window.innerHeight;
        this.startX = move.startOffsetX;
        this.startY = move.startOffsetY;
        this.crossHairsTop = move.pageY;
        this.crossHairsLeft = move.pageX;
        
        this.croppedImageWidth = Math.abs(endX - startX);
        this.croppedImageHeight = Math.abs(endY - startY);

        if (endX >= startX && endY >= startY) {
          this.borderWidth = startY + 'px ' + (windowWidth - endX) + 'px ' + (windowHeight - endY) + 'px ' + startX + 'px';
          return;
        }
        if (endX >= startX && endY <= startY) {
          this.borderWidth = endY + 'px ' + (windowWidth - endX) + 'px ' + (windowHeight - startY) + 'px ' + startX + 'px';
          return;
        }
        if (endX <= startX && endY <= startY) {
          this.borderWidth = endY + 'px ' + (windowWidth - startX) + 'px ' + (windowHeight - startY) + 'px ' + endX + 'px';
          return;
        }
        if (endX <= startX && endY >= startY) {
          this.borderWidth = startY + 'px ' + (windowWidth - startX) + 'px ' + (windowHeight - endY) + 'px ' + endX + 'px';
          return;
        }
      }

    })));
  }


  takeScreenshot() {
    this.screenShotService.openScreenShot({ open: false, callback: () => { } });

    html2canvas(document.querySelector('body')!, {
      scale: 1,
      useCORS: true,
      allowTaint: true,
    }).then(canvas => {
      const croppedCanvas = document.createElement('canvas'),
        croppedCanvasContext = croppedCanvas.getContext('2d');

      croppedCanvas.width = this.croppedImageWidth;
      croppedCanvas.height = this.croppedImageHeight;

      let startX = this.startX + window.scrollX;
      let startY = this.startY + window.scrollY;
      if (this.crossHairsLeft < this.startX) {
        startX = this.crossHairsLeft;
      }
      if (this.crossHairsTop < this.startY) {
        startY = this.crossHairsTop;
      }

      croppedCanvasContext!.drawImage(canvas, startX, startY, this.croppedImageWidth, this.croppedImageHeight, 0, 0, this.croppedImageWidth, this.croppedImageHeight);
      this.imageUrl = croppedCanvas.toDataURL();

      this.callback(this.imageUrl);
      setTimeout(() => {
        this.crossHairsLeft = 0;
        this.crossHairsTop = 0;
        this.startX = 0;
        this.startY = 0;
      }, 1000)
    });
  }
}
