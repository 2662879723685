
import { of as observableOf } from 'rxjs';
import { delay } from 'rxjs/operators';
import { OAuthService } from '../../../auth/auth.service';
import { ProfileService } from '../../../components/profile/profile.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-upload-image-popup',
  templateUrl: 'upload-image-popup.component.html',
  styleUrls: ['upload-image-popup.component.scss']
})

export class UploadImagePopupComponent implements OnInit {
  @ViewChild('cropwrapper') cropwrapper!: ElementRef;
  @ViewChild('submitBtn', { static: true }) submitBtn!: ElementRef;
  @Input() croppieImage: any;
  @Input() eventUpload: any;
  @Output() cancel = new EventEmitter<boolean>();

  currentImage: any;
  busy: any;
  fileupload: any;
  avatar: any;
  userInfo: any = {};
  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(
    private profileService: ProfileService,
    private oAuthService: OAuthService
  ) { }

  ngOnInit() {
    this.userInfo = this.oAuthService.getUserInfo();
    this.avatar = this.userInfo.avatar;
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: any) {    
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  saveImageFromCroppie() {
    this.submitBtn.nativeElement.disabled = true;
    this.submitBtn.nativeElement.classList.add('onclic');
    this.currentImage = this.croppedImage;

    const data = {
      email: this.userInfo.Email,
      imageBase64: this.currentImage,
      extFile: 'png'
    }
    
    this.busy = this.profileService.changeProfileAvatar(data).subscribe((res: any) => {
      if (res.status === 200) {
        this.oAuthService.setToken(res.access_token);
          this.exit();
          location.reload();
      } else {
        const delayedObservable = observableOf([1]).pipe(delay(1000));
        this.busy = delayedObservable.subscribe(() => {
          alert('Tải ảnh không thành công');
          this.exit();
        });
      }
    });
  }

  exit() {
    this.croppieImage = null;
    this.cancel.next(true);
  }
}
