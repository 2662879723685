
import { STATE_MODAL } from './state.const';
import { Subscription } from 'rxjs';
import { ModalCommonService } from './modal-common.service';
import { Component, OnInit, Input, OnDestroy, ContentChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { modalAnimation } from '../../animations/modal.animation';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-modal-common',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal-common.component.html',
  styleUrls: ['./modal-common.component.scss'],
  animations: [modalAnimation]
})
export class ModalCommonComponent implements OnInit, OnDestroy {
  @Input() id: any;
  @Input() clickOutsideClose = true;
  @Input() displayX = false;
  @Input() backgroundColor: any;
  @Input() backgroundNone: any;
  @Input() bottomModal = false;
  @Output() onCloseEvent = new EventEmitter();
  subcription = new Subscription();
  showModal: any = false;
  @ContentChild(TemplateRef)
  templateRef!: TemplateRef<any>;

  constructor(private modalCommonService: ModalCommonService) { }

  ngOnInit(): void {
    this.subcription = this.modalCommonService.addModalItem(this.id).subscribe((data: any) => {
      if (data.type === STATE_MODAL.OPEN) {
        this.openModal();
      }
      if (data.type === STATE_MODAL.CLOSE) {
        this.closeModal();
      }
    });
  }

  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  onCloseModal() {
    this.closeModal();
    this.onCloseEvent.emit(true);
  }
  clickOutside() {
    if (this.clickOutsideClose) {
      this.closeModal();
    }
  }

  ngOnDestroy() {
    this.modalCommonService.removeModalItem(this.id);
    this.subcription.unsubscribe();
  }
}
