import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { OAuthService } from '../../auth/auth.service';
import { ListChangeCourseComponent } from './list-change-course/list-change-course.component';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ModalCommonComponent } from '../../common/popup/modal-common/modal-common.component';
import { MODAL_ID } from '../../common/popup/modal-common/modal-id.const';
import { ModalCommonService } from '../../common/popup/modal-common/modal-common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from './profile.service';
import { forkJoin } from 'rxjs';
import { UploadImagePopupModule } from '../../common/popup/upload-image-popup/upload-image-popup.module';
import { ToastrService } from 'ngx-toastr';
import { PopupOpenbookComponent } from '../../layout/full-layout/sidebar/popup-openbook/popup-openbook.component';
import { MixpanelService } from '../../common/mixpanel/mixpanel.service';
import { MIXPANEL } from '../../common/mixpanel/mixpanel.constant';
@Component({
  selector: 'app-profile',
  standalone: true,
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
  imports: [
    CommonModule,
    ListChangeCourseComponent,
    FormsModule,
    ReactiveFormsModule,
    ModalCommonComponent,
    UploadImagePopupModule,
    PopupOpenbookComponent,
  ],
})
export class ProfileComponent implements OnInit {
  showCourse = false;
  userInfoForm!: UntypedFormGroup;
  userInfo: any;
  idOpenBook = MODAL_ID.COMMON.OPENBOOK;
  code: any;
  text = '';
  croppieImage: any;
  eventUpload: any;
  check = false;
  alertName: any;
  alertPhone: any;
  userName: any;
  firstPhone;
  @ViewChild('file', { static: true }) file: ElementRef | undefined;
  @ViewChild('spanName', { static: true }) spanName!: ElementRef;
  @ViewChild('inputName', { static: true }) inputName!: ElementRef;
  @ViewChildren('choicebook') choicebook!: QueryList<ElementRef>;

  constructor(
    private authService: OAuthService,
    private modalCommonService: ModalCommonService,
    private router: Router,
    private ProfileService: ProfileService,
    private oAuthService: OAuthService,
    private toastService: ToastrService,
    private route: ActivatedRoute,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit(): void {
    this.ProfileService.profileInfo$.subscribe((res: any) => {
      if (res) {
        this.userName = res.display_name;
      }
    });
    this.getProfile();
    this.createForm();
  }
  ngAfterViewInit(): void {
    this.checkparam();
  }
  scrollToTestElements(): void {
    this.choicebook.forEach((a) => {
      if (a && a.nativeElement) {
        a.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    });
  }
  checkparam() {
    this.route.queryParams.subscribe((params) => {
      if (params['choice']) {
        this.scrollToTestElements();
        setTimeout(() => {
          this.showCourse = true;
        }, 1000);
      }
    });
  }

  getProfile() {
    this.userInfo = this.authService.getUserInfo();
    this.userName = this.userInfo.DisplayName;
  }

  createForm() {
    this.userInfoForm = new UntypedFormGroup({
      displayName: new UntypedFormControl(this.userInfo?.DisplayName, [
        Validators.required,
      ]),
      phoneNumber: new UntypedFormControl(this.userInfo?.PhoneNumber, []),
      userName: new UntypedFormControl(
        this.userInfo?.userName,
        Validators.email
      ),
    });
  }

  get displayName() {
    return this.userInfoForm.get('displayName');
  }
  get phoneNumber() {
    return this.userInfoForm.get('phoneNumber');
  }

  dropCourse() {
    this.showCourse = !this.showCourse;
  }

  submitChange(form: UntypedFormGroup) {
    if (this.userInfoForm.invalid) {
      return;
    }
    const userInfoForm = {
      displayName: form.value.displayName,
      phoneNumber: Number(form.value.phoneNumber),
      phoneCountry: this.firstPhone,
    };
    this.ProfileService.changeProfile(userInfoForm).subscribe((res: any) => {
      if (res.status === 200) {
        this.oAuthService.setToken(res.access_token);
        this.ProfileService.profileChanged(res);
        this.toastService.success('Thay đổi thông tin thành công');
        this.check = false;
      } else {
        this.toastService.success('Thay đổi thông tin không thành công');

      }
    });

    // forkJoin([this.ProfileService.changeProfile(userInfoForm), this.ProfileService.changePhonenumber(userInfoForm)]).subscribe(([data1, data2]: any) => {
    //   if (data1.status === 200) {
    //     this.oAuthService.setToken(data1.access_token);
    //     this.ProfileService.profileChanged(data1);
    //   }
    //   if (data2.status === 200) {
    //     this.oAuthService.setToken(data2.access_token);
    //   }
    //   this.toastService.success('Thay đổi thành công');
    //   this.check = false
    // })
  }
  openpopup(_book: any) {
    this.modalCommonService.openModal(this.idOpenBook);
  }
  logout() {
    this.mixpanelService.track(MIXPANEL.SIGN_OUT.name, {
      action: MIXPANEL.SIGN_OUT.actions.SIGN_OUT,
      preview_screen: 'Profile',
    });
    localStorage.removeItem('token');
    localStorage.removeItem('avatar');
    localStorage.removeItem('lastUrl');
    localStorage.removeItem('user_traits');
    localStorage.removeItem('current-course');
    localStorage.removeItem('current-target');
    this.router.navigate(['/login']);
    this.oAuthService.logOut();
    return;
  }
  uploadImage() {
    this.croppieImage = null;
    this.text = '';
    if (this.file) {
      this.file.nativeElement.value = '';
      this.file.nativeElement.click();
    }
  }
  imageUploadEvent(evt: any) {
    if (!evt.target) {
      return;
    }
    if (!evt.target.files) {
      return;
    }
    if (evt.target.files.length !== 1) {
      return;
    }
    if (evt.target.files[0].size > 5242880) {
      this.text = 'Dung lượng ảnh không được vượt quá 5MB';
      this.toastService.warning(this.text);
      return;
    }
    this.croppieImage = true;
    this.eventUpload = evt;
  }
  cancelUpload() {
    this.croppieImage = null;
    if (this.file) {
      this.file.nativeElement.value = '';
    }
  }

  onKeydownMain(a: any) {
    if (a) {
      this.check = true;
      this.resizeInput(this.userInfoForm);
    }
  }
  resizeInput(form: UntypedFormGroup) {
    let userInfoForm = {
      displayName: form.value.displayName,
      phoneNumber: form.value.phoneNumber,
    };
    var phone = userInfoForm.phoneNumber.replace(/^84/, '0');
    var phoneCountry = phone.slice(0, 2).toString();
    var phoneCountryKorea = phone.slice(0, 3).toString();
    var phoneCountryAll = ['03', '05', '07', '08', '09'];
    var phoneCountryAllKorea = [
      '010',
      '011',
      '016',
      '017',
      '019',
      '012',
      '015',
      '018',
      '013',
    ];
    var a = phoneCountryAll.includes(phoneCountry);
    var b = phoneCountryAllKorea.includes(phoneCountryKorea);
    if (a === true) {
      this.firstPhone = 84;
    } else {
      this.firstPhone = 82;
    }
    console.log(this.firstPhone);

    if (userInfoForm.displayName.length < 2) {
      this.alertName = '*Tên không hợp lệ';
    } else {
      this.alertName = '';
    }
    if (
      (phone.length != 10 || a === false) &&
      (phone.length != 11 || b === false)
    ) {
      this.alertPhone = '*Số điện thoại không hợp lệ';
    } else {
      this.alertPhone = '';
    }
    if (
      userInfoForm.displayName.length < 2 ||
      ((phone.length != 10 || a === false) &&
        (phone.length != 11 || b === false))
    ) {
      this.check = false;
    }
  }
}
