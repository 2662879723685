import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { LOCAL_FIELD } from 'src/app/common/constants/localstorage';
import { OAuthService } from '../auth.service';

@Injectable({ providedIn: 'root' })
export class UnauthorizedInterceptor implements HttpInterceptor {
  LIST_EXCEPT_AUTH = [
  ];
  LIST_ACCEPT_AUTH = [];
  constructor(private oAuthService: OAuthService) {}
  noExcept(url: string) {
    return this.LIST_EXCEPT_AUTH.every((exc) => {
      return !url.includes(exc);
    });
  }

  yesExcept(url: string) {
    return this.LIST_ACCEPT_AUTH.some((exc) => {
      return url.includes(exc);
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.indexOf('.json') >= 0) {
      return next.handle(request);
    }
    if (this.oAuthService.getToken()) {
      if (this.noExcept(request.url)) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.oAuthService.getToken()}`,
          },
        });
      }

      if (this.yesExcept(request.url)) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.oAuthService.getToken()}`,
          },
        });
      }
    }
    const req = request;
    return next.handle(req).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            // localStorage.setItem(LOCAL_FIELD.LAST_URL, window.location.href);
            this.oAuthService.logOut();
          }
        }
        return throwError(() => error);
      })
    );
  }
}
