<div class="container-profile">
    <div class="info-container" [formGroup]="userInfoForm">
        <div class="info">
            <div class="info-content">
                <div class="avatar-container">
                    <div class="avatar">
                        <div class="image">
                            <img [src]=userInfo?.avatar alt="">
                        </div>
                        <div class="edit-avatar">
                            <img src="assets/profile/edit-avatar.svg" alt="" (click)="uploadImage()">
                        </div>
                        <input #file type="file" class="hidden" id="fileupload" (change)="imageUploadEvent($event)"
                            accept="image/gif, image/jpeg, image/png" />
                        <app-upload-image-popup *ngIf="croppieImage" [eventUpload]="eventUpload"
                            [croppieImage]="croppieImage" (cancel)="cancelUpload()">
                        </app-upload-image-popup>
                    </div>
                    <div class="user-name">
                        {{userName}}
                    </div>
                </div>
                <div class="info-user">
                    <div class="top">
                        <div class="left">
                            <div class="item-field">
                                <div class="name-field">
                                    Tên hiển thị
                                </div>
                                <div class="field-value">
                                    <input type="text" (ngModelChange)="onKeydownMain($event)"
                                        placeholder="topik@edmicro.com" formControlName="displayName">
                                </div>
                                <div class="field-image">
                                    <img src="assets/profile/edit.svg" alt="">
                                </div>
                                <div class="warning">
                                    {{alertName}}
                                </div>
                            </div>
                            <div class="item-field">
                                <div class="name-field">
                                    Số điện thoại
                                </div>
                                <div class="field-value">
                                    <input type="tel" (ngModelChange)="onKeydownMain($event)" placeholder="0987654321"
                                        formControlName="phoneNumber">
                                </div>
                                <div class="field-image">
                                    <img src="assets/profile/edit.svg" alt="">
                                </div>
                                <div class="warning">
                                    {{alertPhone}}
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <!-- <div class="item-field password">
                                <div class="name-field">
                                    Mật khẩu
                                </div>
                                <div class="field-value">
                                    ******
                                </div>
                                <div class="field-image">
                                    <img src="assets/profile/lock.svg" alt="">
                                </div>
                            </div> -->
                            <div class="item-field">
                                <div class="name-field">
                                    E-mail
                                </div>
                                <div class="field-value">
                                    <input type="text" placeholder="ielts@edmicro.com" formControlName="userName"
                                        readonly>
                                </div>
                                <div class="field-image">
                                    <img src="assets/profile/lock.svg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom">
                        <div class="btn-update">
                            <button [class.check]="check" [disabled]="check === false"
                                (click)="submitChange(userInfoForm)">
                                Cập nhật
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-list-course">
            <div class="title" (click)="dropCourse()" #choicebook>
                <div class="image">
                    <div class="content-title">
                        <img src="assets/profile/Change.svg" alt=""> Đổi khóa học
                    </div>
                    <img src="assets/common/drop-icon.svg" alt="" [class.drop-top]="!showCourse">
                </div>
            </div>
            <div class="course" *ngIf="showCourse">
                <app-list-change-course (save)="openpopup($event)"></app-list-change-course>
            </div>
        </div>
        <div class="support">
            <div class="title">
                Hỗ trợ
            </div>
            <div class="info-cursor">
                <div class="text-content">
                    <img src="assets/profile/phone-call.svg" alt="">
                    <div>Hotline: <span class="text-bold">096 960 2660</span></div>
                </div>
                <div class="text-content">
                    <img src="assets/profile/mail.svg" alt="">
                    <div> Email: <span class="text-bold">hotro&#64;onluyen.vn</span></div>
                </div>
                <div class="text-content">
                    <img src="assets/profile/book.svg" alt="">
                    <a href="https://edmicrohelp.notion.site/H-ng-d-n-v-H-tr-4f0f13594c5a4f3b9fc9899e49efb446"
                        target="_blank" style="text-decoration: none; color: inherit;">Hướng dẫn sử dụng</a>
                </div>
            </div>
            <div class="info-cursor">
                <div class="text-content">
                    <img src="assets/profile/file-text.svg" alt="">
                    <a href="https://www.notion.so/edmicrohelp/i-u-kho-n-s-d-ng-v-Ch-nh-s-ch-b-o-m-t-ec92ec3e12d74c7d923bd55c1c555f16"
                        target="_blank" style="text-decoration: none; color: inherit;">Chính sách bảo mật</a>
                </div>
                <div class="text-content">
                    <img src="assets/profile/shield.svg" alt="">
                    <a href="https://www.notion.so/edmicrohelp/i-u-kho-n-s-d-ng-v-Ch-nh-s-ch-b-o-m-t-ec92ec3e12d74c7d923bd55c1c555f16"
                        target="_blank" style="text-decoration: none; color: inherit;">Điều khoản dịch vụ</a>
                </div>

            </div>
            <div class="info-cursor">
                <div class="text-content">
                    <img src="assets/profile/globe.svg" alt="">
                    <div>Website: <span class="text-red text-bold">topik.onluyen.vn</span></div>
                </div>
            </div>
            <div class="info-cursor-1">
                <div class="text-content-1">
                    <img src="assets/profile/log-out.svg" alt="">
                    <span class="text-red bold log-out" (click)="logout()">Đăng xuất</span>
                </div>
            </div>
        </div>
    </div>
    <div class="list-course" #choicebook>
        <div class="list-course">
            <div class="title">
                Đổi khóa học
            </div>
            <div class="course">
                <app-list-change-course (save)="openpopup($event)"></app-list-change-course>
            </div>
        </div>
    </div>
</div>