import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CONSTANTS } from '../../../constants/constants';
import { MixpanelService } from '../../../common/mixpanel/mixpanel.service';
import { MIXPANEL } from '../../../common/mixpanel/mixpanel.constant';

@Component({
  selector: 'app-login-google',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './login-google.component.html',
  styleUrl: './login-google.component.scss'
})
export class LoginGoogleComponent implements OnInit {

  email = 'hotro@onluyen.vn'

  constructor(
    private mixpanelService: MixpanelService
  ) {}
  ngOnInit() { 
    
  }
  loginGoogle(){
    // this.mixpanelService.track(MIXPANEL.SIGN_UP.name, {
    //   action: MIXPANEL.SIGN_UP.actions.SIGN_UP,
    // });
    window.location.href = `${environment.AUTH}${CONSTANTS.OAUTH.GOOGLE}?codeApp=${CONSTANTS.CODE_APP}&open_app=${environment.OPEN_APP}&redirect=auto`;
  }
}
