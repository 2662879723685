import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalCommonService } from '../modal-common/modal-common.service';
import { MODAL_ID } from '../modal-common/modal-id.const';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-confirm-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit, OnDestroy {
  idModal = MODAL_ID.COMMON.CONFIRM;
  subscriptions: any;
  infoConfirm: any;
  constructor(private modalCommonService: ModalCommonService) { }

  ngOnInit(): void {
    this.subscriptions = this.modalCommonService.getModalData(this.idModal).subscribe((res: { type: number, data: any }) => {
      if (res && res.data) {
        this.infoConfirm = res.data;
      }
    });
  }
  cancel() {
    this.modalCommonService.closeModal(this.idModal);
    if(this.infoConfirm?.cancelEvent){
      this.infoConfirm?.cancelEvent(true);
    }

  }
  refuse() {

    this.infoConfirm.refuseEvent(true);
  }
  accept() {

    this.infoConfirm.okEvent(true);
  }
  ngOnDestroy() {
    this.subscriptions?.unsubscribe();
  }

}
