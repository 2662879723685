<div class="container-right-side">
    <div class="course">
        <div class="course-active" (click)="dropCourse()" >
            <div class="info">
                <div class="icon">
                    <img [src]="currentCourse?.thumbnail || image" alt="">
                </div>
                <div class="right">
                    <div class="name">
                        {{currentCourse?.name ||'Chọn khóa học'}}
                    </div>
                    <div class="des">
                        {{currentCourse?.des}}
                    </div>
                </div>
            </div>
            <div class="icon-drop" [class.rotate-img]="showCourse">
                <img src="assets/common/drop-icon.svg" alt="">
            </div>
        </div>
        <div class="list-drop" *ngIf="showCourse">
            <div class="item-drop" *ngFor="let item of listAccess" [class.active]="item?.id === currentCourse?.id" [class.access]="!item.isAccess"
                (click)="OpenUnlockBooks('start', item)">
                <div class="info">
                    <div class="icon">
                        <img [src]="item?.thumbnail" alt="">
                    </div>
                    <div class="right">
                        <div class="name">
                            {{item?.name}}
                        </div>
                        <div class="des">
                            {{item?.des}}
                        </div>
                    </div>
                </div>
                <div class="icon-drop">
                    <img src="assets/common/pause-icon.svg" alt="" *ngIf="item.isCurrent">
                    <img src="assets/common/play-icon.svg" alt="" *ngIf="!item.isCurrent && item.isAccess">
                    <img src="assets/common/icon-lock.svg" alt="" *ngIf="!item.isAccess">

                </div>
            </div>
        </div>
    </div>
    <app-quick-report *ngIf="!isLearn">

    </app-quick-report>
    <app-report-learn *ngIf="isLearn">

    </app-report-learn>
    <app-lesson-continue *ngIf="isLearn">

    </app-lesson-continue>
</div>