<div class="header-container">
    <div class="user">
        <div class="user-name" (click)="goToProfile()">
            {{userName}}
        </div>
        <div class="avatar" (click)="goToProfile()">
            <ngx-avatars [style]="{cursor: 'pointer'}" [src]="userInfo?.avatar" [name]="userInfo?.userName"
                size="48"></ngx-avatars>
        </div>
    </div>
    <div class="back-btn" *ngIf="isBackBtn && backUrl !==''">
        <button (click)="back()">
            <img src="assets/common/back-icon.svg" alt="">
            Quay lại
        </button>

    </div>
    <div class="title" *ngIf="!isBackBtn">
        TOPIK Master
    </div>
</div>