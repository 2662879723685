export function mixpanelDevice() {
    const userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    if (macosPlatforms.indexOf(platform) !== -1) {
        return 'webios';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        return 'webios';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        return 'webdesktop';
    } else if (/Android/.test(userAgent)) {
        return 'webandroid';

    } else if (/Linux/.test(platform)) {
        return 'webdesktop';
    }

    return 'unknow';
}
