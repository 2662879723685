import { Injectable } from '@angular/core';
import { CONSTANTS } from '../../../constants/constants';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler } from '../../../common/error/HttpErrorResponse';
import { BehaviorSubject, catchError } from 'rxjs';


@Injectable(
  {
    providedIn: 'root'
  }
)
export class ListChangeService {
  private currentCourse = new BehaviorSubject<any>(null);
  public $currentCourse = this.currentCourse.asObservable();
  myHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  });

  constructor(
    private _http: HttpClient,
    private httpErrorHandler: HttpErrorHandler,
  ) { }
  getListCourse() {
    return this._http.get(environment.BASE_API_URL + CONSTANTS.API.COURSE.LIST_COURSE, {
      params: {
        codeApp: CONSTANTS.CODE_APP,
        pageIndex: 0,
        pageSize: 1000,
      }, headers: this.myHeader
    });
  }
  // setCurrentCourse(course: any) {
  //   this.currentCourse.next(course);
  // }
  postChangeCourse(courseId: any) {
    return this._http.post(environment.BASE_API_URL + CONSTANTS.API.COURSE.POST_CHANGE_COURSE + courseId, { idCourse: courseId })
      .pipe(
        catchError(this.httpErrorHandler.handleError)
      );
  }
}
