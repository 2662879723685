import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../../common/loading-service/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  LIST_DISABLED_LOADING = [
  ]
  NO_CACHE = [

  ]

  constructor(private loadingService: LoadingService) { }

  noExcept(url: string) {
    return this.LIST_DISABLED_LOADING.some(exc => {
      return url.includes(exc);
    });
  }

  checkCache(url: any) {
    return this.NO_CACHE.every(c => url.includes(c));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (this.checkCache(request.url)) {
      if (request.url.indexOf('translate.googleapis.com') >= 0 || request.url.indexOf('api.tracau.vn') >= 0) {
      } else {
        request = request.clone({
          setHeaders: {
            'Cache-Control': 'no-cache'
          }
        });
      }
    }
    if (!this.noExcept(request.url)) {
      this.totalRequests++;
      this.loadingService.setLoading(true);
      return next?.handle(request).pipe(
        finalize(() => {
          this.totalRequests--;
          if (this.totalRequests === 0) {
            this.loadingService.setLoading(false);
          }
        })
      );
    }
    return next?.handle(request)
  }
}