<div class="full-layout-container" *ngIf="!fullScreen">
    <app-header >
    </app-header>
    <div class="side-bar">
        <app-sidebar>

        </app-sidebar>
    </div>
    <div class="content">
        <div class="content-scroll">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="right-side" *ngIf="isRightSide && screenWidth > 1250">
        <app-right-side>

        </app-right-side>
    </div>
</div>
<div class="fullscreen-layout-container" *ngIf="fullScreen">
    <div class="content">
        <div class="content-scroll">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<app-loading-service></app-loading-service>
<app-modal-common [id]="idConfirm" [clickOutsideClose]="false" [displayX]="false">
    <ng-template>
        <app-confirm-modal></app-confirm-modal>

    </ng-template>
  </app-modal-common>
  <app-modal-common [id]="idModalSendError" [clickOutsideClose]="true" [displayX]="true" >
    <ng-template>
      <app-send-error></app-send-error>
    </ng-template>
  </app-modal-common>
  <app-screenshot ></app-screenshot>
  <app-modal-common [id]="idOpenBook" [clickOutsideClose]="true" >
    <ng-template>
        <app-popup-openbook></app-popup-openbook>
    </ng-template>
  </app-modal-common>
  <app-modal-common [id]="idGetStreak" [clickOutsideClose]="true" [bottomModal]="true" [displayX]="true" >
    <ng-template>
        <app-popup-get-streak></app-popup-get-streak>
    </ng-template>
  </app-modal-common>