import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from '../auth/auth.service';
// import { PlatformLocation } from '@angular/common';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private oAuthService: OAuthService,
  ) { }

  canActivate(_router: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    if (this.oAuthService.getToken()) {
      return true;
    }
    this.router.navigate(['/login']);
    this.oAuthService.logOut();
    return false;
  }

}
