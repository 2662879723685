import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { OAuthService } from '../../auth/auth.service';
import { environment } from '../../environments/environment';
import { mixpanelDevice } from '../function/mixpanelDevice';
import { getUserInfoStraits } from '../function/auth/auth-function';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  private mixpanelToken = environment.MIXPANEL_TOKEN;

  /**
   * constructor
   * get mixpanel token and initialize
   */
  constructor(private oAuthService: OAuthService) {
    this.init();
  }

  updateMixpanel(userInfo: any) {
    // check MixpanelId in token
    if (userInfo.idMixpanel && userInfo.idMixpanel !== environment.MIXPANEL_TOKEN
    ) {
      this.identify(userInfo.idMixpanel);
      this.track('Sign In', {
        platform: window.navigator.platform,
        type: 'firebase',
        isMixpanel: 'OLD',
        userDevice: mixpanelDevice(),
      });
      // update mixpanel info
      this.setPeople(userInfo);
    } else {
      this.createAlias(userInfo.userId);
      // update
      this.setPeople(userInfo);
      this.track('Sign In', {
        platform: window.navigator.platform,
        type: 'firebase',
        isMixpanel: 'NEW',
        userDevice: mixpanelDevice(),
      });
      const idMixpanel = this.getMixpanelId();

      this.oAuthService.updateMixpanel(idMixpanel).subscribe((res: any) => {
        if (res && res.access_token) {
          this.oAuthService.setToken(res.access_token);
        }
      });
    }
  }
  /**
   * Initialize mixpanel.
   */
  init(): void {
    mixpanel.init(this.mixpanelToken);
    const userInfo = getUserInfoStraits()
    if (userInfo && userInfo.idMixpanel) {
      this.identify(userInfo.idMixpanel);
    }
  }

  /**
   * Create new Alias for user
   * Call this method only once in user lifecycle
   *
   * @param {string} alias
   */
  createAlias(alias: string) {
    mixpanel.alias(alias, mixpanel.get_distinct_id());
  }

  /**
   * Identify User
   *
   * @param {string} alias
   */
  identify(alias: string) {
    mixpanel.identify(alias);
  }
  /**
   *
   * @param id
   * @param action
   * @param callback
   */
  getMixpanelId() {
    return mixpanel.get_distinct_id();
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}, callback?: any): void {
    const userInfo = this.oAuthService.getUserInfo();
    if (action) {
      action = {
        ...action,
        email: userInfo?.email || '',
        userId: userInfo?.userId || '',
        userName: userInfo?.userName || '',
        timeLocal: new Date().getTime() / 1000,
        name: userInfo?.userName || '',
        id_course: localStorage.getItem('current-course'),
        userDevice: mixpanelDevice(),
        platform: window.navigator.platform,
        
      };
      console.log(action);
      
    }
    mixpanel.track(id, action, callback);
  }
  /**
   * setup mixpannel
   *
   */
  // setup() {
  //   mixpanel.loggingEnabled = false;
  //   this.setSuperProperties({ Platform: 'web' });
  // }

  /**
   * setPeople
   * Store user profiles in Mixpanel's People Analytics product
   * @param {Object} properties
   */
  setPeople(properties: any = {}): void {
    mixpanel.people.set(properties);
  }

  /**
   * setSuperProperties
   *
   * @param {object} properties
   */
  setSuperProperties(properties: any) {
    mixpanel.register(properties);
  }

  /**
   * sendEvent
   *
   * @param {string} event
   * @param {object} properties
   */
  sendEvent(event: string, properties?: any) {
    if (properties) {
      mixpanel.track(event, properties);
    } else {
      this.trackEvent(event);
    }
  }

  /**
   * trackEvent
   * @param {string} event
   */
  trackEvent(event: string) {
    mixpanel.track(event);
  }

  /**
   * Reset Mixpanel
   */
  logout() {
    mixpanel.reset();
  }
}
