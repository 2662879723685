import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '../../../constants/constants';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, catchError } from 'rxjs';
import { HttpErrorHandler } from '../../../common/error/HttpErrorResponse';


@Injectable({
  providedIn:'root'
})
export class RightSideService {
  myHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  });
  private currentCourse = new BehaviorSubject<any>(null);
  public $currentCourse = this.currentCourse.asObservable();
  constructor(
    private _http: HttpClient,
    private httpErrorHandler: HttpErrorHandler,
  ) { }
  getListCourse() {
    return this._http.get(environment.BASE_API_URL + CONSTANTS.API.COURSE.LIST_COURSE);
  }
  setCurrentCourse(course: any) {
    this.currentCourse.next(course);
  }
  postChangeCourse(courseId: any) {
    return this._http.post(environment.BASE_API_URL + CONSTANTS.API.COURSE.POST_CHANGE_COURSE + courseId, { idCourse: courseId })
      .pipe(
        catchError(this.httpErrorHandler.handleError)
      );
  }
  getReport(type: any) {
    return this._http.get(environment.BASE_API_URL + CONSTANTS.API.REPORT.GET_REPORT + '?type=' + type, {
       headers: this.myHeader
    });
  }
  getChartData(){
    return this._http.get(environment.BASE_API_URL + CONSTANTS.API.REPORT.CHART_COURSE, { headers: this.myHeader });

  }
}
