import { Component, OnInit } from '@angular/core';
import { LISTMENU } from './listMenu';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ModalCommonComponent } from '../../../common/popup/modal-common/modal-common.component';
import { MODAL_ID } from '../../../common/popup/modal-common/modal-id.const';
import { ModalCommonService } from '../../../common/popup/modal-common/modal-common.service';

@Component({
    selector: 'app-sidebar',
    standalone: true,
    templateUrl: './sidebar.component.html',
    styleUrl: './sidebar.component.scss',
    imports: [CommonModule,
        RouterModule,
        ModalCommonComponent]
})
export class SidebarComponent implements OnInit {
  listMenu = LISTMENU;
  idOpenBook = MODAL_ID.COMMON.OPENBOOK ;
  constructor(
    private modalCommonService:ModalCommonService
  ) {

  }
  ngOnInit() {

  }
  openpopup() {
    this.modalCommonService.openModal(this.idOpenBook)
  }
} 
