import { ActivatedRoute, Router, } from '@angular/router';

export function reloadComponent(router: Router, activatedRoute: ActivatedRoute) {
    router.routeReuseStrategy.shouldReuseRoute = () => false;
    router.onSameUrlNavigation = 'reload';
    router.navigate(['./'], {
        relativeTo: activatedRoute,
        queryParams: activatedRoute.snapshot.queryParams
    });
    setTimeout(()=>{
        router.onSameUrlNavigation = 'ignore';
    }, 500)
}
