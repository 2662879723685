export enum EnumActionInLessonIeltsCourse {

    /// <summary>
    /// Video youtube or vimeo
    /// </summary>
    //[Description("Video youtube or vimeo")]
    Video = 1,
  
    /// <summary>
    /// Bài giảng tương tác
    /// </summary>
    //[Description("Bài giảng tương tác")]
    // Mathigon = 2,
  
    /// <summary>
    /// Tự Luyện - K1
    /// </summary>
    //[Description("Tự Luyện - K12")]
    // PracticeCore = 3,
    /// <summary>
    /// Kiểm tra - K12
    /// </summary>
    //[Description("Kiểm tra - K12")]
    // TestCore = 4,
    /// <summary>
    /// Tự Luyện Ielts - Vocabular
    /// </summary> 
    //[Description("Tự Luyện Ielts - Vocabulary")]
    PracticeIeltsVocabulary = 5,
    /// <summary>
    /// Tự Luyện Ielts - Grammar
    /// </summary>
    //[Description("Tự Luyện Ielts - Grammar")]
    PracticeIeltsGrammar = 6,
    /// <summary>
    /// Tự Luyện Ielts - Reading
    /// </summary>
    //[Description("Tự Luyện Ielts - Reading")]
    PracticeIeltsReading = 7,
    /// <summary>
    /// Tự Luyện Ielts - Listening
    /// </summary>
    //[Description("Tự Luyện Ielts - Listening")]
    PracticeIeltsListening = 8,
    /// <summary>
    /// Tự Luyện Ielts - Speaking
    /// </summary>
    //[Description("Tự Luyện Ielts - Speaking")]
    PracticeIeltsSpeaking = 9,
    /// <summary>
    /// Tự Luyện Ielts - Writing
    /// </summary>
    //[Description("Tự Luyện Ielts - Writing")]
    PracticeIeltsWriting = 10,
    // TestIelts = 11,
    FlashCard = 12,
    // PDF = 18,

  
  }
 export enum sectionEnum {
    Listening = 1,
    Reading = 2,
    Writing = 3,
    Vocabulary = 4,
    Speaking = 5,
    Grammar = 7,

  }
  
  export enum StatusDoPart {
    notyet = 0,
    doing = 1,
    done = 2,

}