export const MIXPANEL = {
  SIGN_UP: {
    name: 'signup',
    actions: {
      SIGN_UP: 'sign_up',
      LOGIN_SUCCESS: 'login_success',
    },
  },
  ENTER_CODE: {
    name: 'enter_code',
    actions: {
      ENTER_CODE: 'enter_code',
      SEARCH_CODE: 'search_code',
      CHOOSE_FEATURE: 'choose_feature',
    },
  },
  CONTINUE_STUDY: {
    name: 'continue_study',
    actions: {
      CONTINUE_STUDY: 'continue_study',
    },
  },
  CHANGE_COURSE: {
    name: 'change_course',
    actions: {
      CHANGE_COURSE: 'change_course',
      CHOOSE_COURSE: 'choose_course',
    },
  },
  TEST: {
    name: 'test',
    actions: {
      CHOOSE_EXAM: 'choose_exam',
      START_TEST: 'start_test',
      CONTINUE_TEST: 'continue_test',
      VIEW_RESULT: 'view_result',
      TEST_LISTEN_AUDIO: 'test_listen_audio',
      TEST_NEXT_PART: 'test_next_part',
      EXIT_TEST: 'exit_test',
      TEST_SUBMIT_PART: 'test_submit_part',
      SUBMIT_TEST: 'submit_test',
      EXIT_RESULT: 'exit_result',
      RETAKE_TEST: 'retake_test',
      REVIEW_TEST: 'review_test',
      // exit_result: 'exit_result',
    },
  },
  LEARN: {
    name: 'learn',
    actions: {
      LEARN_CHOOSE_TOPIC: 'learn_choose_topic',
      LEARN_CHOOSE_LESSON: 'learn_choose_lesson',
      LEARN_EXIT: 'learn_exit',
      LEARN_MENU: 'learn_menu',
      LEARN_NEXT: 'learn_next',
    },
  },
  REPORT: {
    name: 'report',
    actions: {
      FILTER_REPORT: 'filter_report',
      VIEW_HISTORY: 'view_history',
    },
  },
  SIGN_OUT: {
    name: 'sign_out',
    actions: {
      SIGN_OUT: 'sign_out',
    },
  },
};
