import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MODAL_ID } from '../modal-common/modal-id.const';
import { ModalCommonService } from '../modal-common/modal-common.service';
import { LessonService } from '../../../components/learn/lesson.service';

@Component({
  selector: 'app-popup-get-streak',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './popup-get-streak.component.html',
  styleUrl: './popup-get-streak.component.scss'
})
export class PopupGetStreakComponent implements OnInit {
  totalStreak = 0;
  idGetStreak = MODAL_ID.COMMON.GET_STEAK;
  type: any;
  dayWithTimeStamp: any = [];
  timeStampLastDate: any;
    constructor(
    private modalCommonService: ModalCommonService,
    private lessonService: LessonService

  ) {

  }
  ngOnInit() { 
    this.modalCommonService.getModalData(this.idGetStreak).subscribe((res: any) => {
      if(res.data) {
        this.type = res.data.type;
        this.getDataSteak();
      }
      
    })
  }
  getDataSteak() {
    this.lessonService.getStreak(this.type).subscribe((res: any) => {
      if(res) {
        this.totalStreak = res.totalDay;
        this.timeStampLastDate = this.getTimeStampWithDay(res.lastDate.toString());
        this.getDayOfWeek(new Date());
      }
    })
  }
  getTimeStampWithDay(dayConvert: any) {
    const year = dayConvert.substring(0, 4);
    const month = parseInt(dayConvert.substring(4, 6)) - 1;
    const day= dayConvert.substring(6, 8);
   return new Date(year, month, day).getTime();
  }
  getDayOfWeek(dateWeek: any) {
    this.dayWithTimeStamp = [];
    const now = new Date(dateWeek);
    const day = dateWeek.getDay();
    const diff = dateWeek.getDate() - day + (day === 0 ? -6 : 1);
    for (let i = 0; i < 7; i++) {
      dateWeek = new Date(now);
      dateWeek.setDate(+diff + i);

      if (i + 2 == 8) {
        dateWeek.setHours(0, 0, 0, 0);
        this.dayWithTimeStamp.push({
          name: "CN",
          date: dateWeek.getTime(),
          index: i,
        });
      } else {
        dateWeek.setHours(0, 0, 0, 0);
        this.dayWithTimeStamp.push({
          name: "T" + (i + 2),
          date: dateWeek.getTime(),
          index: i,
        });
      }

    }
    if (this.timeStampLastDate) {
      let total = this.totalStreak
      const indexLastDay = this.dayWithTimeStamp.findIndex((day: any) => day.date === this.timeStampLastDate);
      if(indexLastDay > -1) {
        this.dayWithTimeStamp = this.dayWithTimeStamp.map((day: any, index: any) => {
          let isDone = false;
          if(index === indexLastDay ) {
            total = total - 1
            isDone = true
          } if(index < indexLastDay && this.totalStreak > 2 && total > 0) {
            isDone = true;
            total = total - 1

          }
          return {
            ...day,
            isDone
          }
        })
        console.log(this.dayWithTimeStamp);
        
      }
      
    }
  }
}
