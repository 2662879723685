<form [formGroup]="reportForm" (ngSubmit)="report(reportForm)">
  <div class="modal-dialog modal-lg box-error" role="document">
    <div class="modal-content">
      <div class="modal-body py-2 px-4 edit-form">
        <div class="heading">
          <div class="title">
            <img src="/assets/common/bug.svg" alt="">
            Báo lỗi câu hỏi <span class="bug">#{{reportId}}</span>
          </div>
          <div class="close-modal" (click)="close()">
            <img src="assets/common/x-icon-v2.svg" alt="">
          </div>
        </div>
        <div class="practice-modal-report mt-4 more-image" *ngIf="reponseText ===''">
          <div class="screen-shot">
            <div class="title-screen-shot py-2">
              Chụp ảnh lỗi
            </div>
            <div class="img-screen-shot" *ngIf="!imgScreenShotDisplay">
              <div class="btn-screen-shot ripple " (click)="capture()">
                <div>
                  <img src="/assets/common/image.svg" alt="">
                </div>
                <div class="screen-shot-description">Chụp ảnh lỗi</div>
              </div>
            </div>
          </div>
          <div class="img-screen-shot" *ngIf="imgScreenShotDisplay">
            <div class="cap-image">
              <div class="delete-img-screen-shot ripple " (click)="removeImg()">
                <img src="assets/common/x-icon-v3.svg" alt="">
              </div>
              <img class="edit-image" [src]="imgScreenShotDisplay" alt="">
            </div>
          </div>
          <div class="form-group">
            <label for="errorType" class=" title-screen-shot py-2">Phân loại lỗi</label>
            <select name="errorType" id="errorType" class="form-control" formControlName="errorType">
              <option value="0" selected disabled hidden>Chọn loại lỗi</option>
              <option value="1">Nội dung câu hỏi sai</option>
              <option value="2">Đáp án câu hỏi sai</option>
              <option value="3">Hình ảnh bị lỗi</option>
              <option value="4">Video sai nội dung</option>
              <option value="5">Trích nguồn</option>
            </select>

          </div> <!-- end form group -->
          <div class="form-group">
            <label for="contentReport" class="title-screen-shot py-2">Vui lòng mô tả lỗi bạn gặp phải</label>
            <textarea name="contentReport" id="contentReport" class="input-content" formControlName="contentReport"
              class="form-control input-content"></textarea>
            <div *ngIf="contentReport?.invalid && (contentReport?.dirty || contentReport?.touched)">
              <label class="error" for="contentReport" *ngIf="contentReport?.errors?.required"> Bạn phải nhập nội dung
                phản
                hồi!</label>

            </div>
          </div>
        </div>
        <!-- end practice-modal-report -->
        <!-- </div> -->
        <div class="text-center loading-overlay" *ngIf="reponseText !==''">
          <div class="response-text">
            {{reponseText}}
          </div>
        </div>
        <!-- <div class="loading-overlay d-none" [class.d-inline-block]="sending">
          <div class="loading-dots">Đang gửi</div>
        </div> -->
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-green btn-big"
          [disabled]="reportForm.invalid || sending || !imgScreenShotDisplay || reportData.error === '0'  "
          [class.d-none]="reponseText !==''">Gửi</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</form> <!-- end form -->