
export const MODAL_ID: any = {
  COMMON: {
    WELLCOM: 'well-com',
    CONFIRM: 'comfirm',
    QRCODE: 'qrcode',
    OPENBOOK:'openbook',
    SEND_ERROR: 'SEND_ERROR',
    COUNTDOWN: 'COUNT_DOWN',
    UPGRADE: 'upgrade',
    GET_STEAK: 'get-streak'
  },
}