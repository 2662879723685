import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, catchError, tap } from 'rxjs';
import { EnumActionInLessonIeltsCourse } from './lesson.enum';
import { CONSTANTS } from '../../constants/constants';
import { environment } from '../../environments/environment';
import { MixpanelService } from '../../common/mixpanel/mixpanel.service';
import { MIXPANEL } from '../../common/mixpanel/mixpanel.constant';
import { HttpErrorHandler } from '../../common/error/HttpErrorResponse';
enum enumPracticeCoreDoing {
  Todo = 0, //Chưa Làm
  Doing = 1, //Đang làm
  Finish = 2, //Kết thúc
}
@Injectable({
  providedIn: 'root',
})
export class LessonService {
  myHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  });
  // jwtHelper = new JwtHelper;

  playerEvent = new Subject();
  $playerEvent = this.playerEvent.asObservable();
  listenFinish = new Subject();
  $listenFinish = this.listenFinish.asObservable();
  listenStart = new Subject();
  $listenStart = this.listenStart.asObservable();
  listLesson: any;
  lessonId: any;
  private openRigtLessonBar = new BehaviorSubject<boolean>(false);

  constructor(
    private _http: HttpClient,
    private toastService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private mixpanelService: MixpanelService,
    private httpErrorHandler: HttpErrorHandler
  ) {}
  finishLessonEvent() {
    this.listenFinish.next('');
  }
  startLessonEvent() {
    this.listenStart.next('');
  }
  toggleNavbar(status?: any) {
    this.openRigtLessonBar.next(status || !this.openRigtLessonBar.value);
  }
  getNavbarStatus() {
    return this.openRigtLessonBar;
  }

  playerEventChange(event: any) {
    this.playerEvent.next(event);
  }

  getListCourse() {
    return this._http.get(
      environment.BASE_API_URL + CONSTANTS.API.COURSE.LIST_COURSE,
      {
        params: {
          codeApp: CONSTANTS.CODE_APP,
          pageIndex: 0,
          pageSize: 1000,
        },
        headers: this.myHeader,
      }
    );
  }
  startLesson(body: {
    idCourse: 'string';
    idUnit: 'string';
    idLesson: 'string';
    type: 'string';
  }) {
    return this._http.put(
      environment.BASE_API_URL + CONSTANTS.API.COURSE.START_LESSON,
      {
        idCourse: body.idCourse,
        idUnit: body.idUnit,
        idLesson: body.idLesson,
      }
    );
  }
  getDetailPractice(practiceId: any) {
    return this._http.get(
      environment.BASE_API_URL +
        CONSTANTS.API.PRACTICE.GET_PRACTICE_CONFIG +
        practiceId,
      { headers: this.myHeader }
    );
  }
  getStreak(action: any) {
    return this._http.get(environment.BASE_API_URL + CONSTANTS.API.STEAK.GET_STREAK + '?action=' + action,  { headers: this.myHeader })
  }
  pushStreak(action: any) {
    return this._http.put(environment.BASE_API_URL + CONSTANTS.API.STEAK.GET_STREAK + '?action=' + action,  { headers: this.myHeader })
  }
  goToPractice(lesson: any, router: any) {
    this.getDetailPractice(lesson.idRef).subscribe((res: any) => {
      const practice = res.data;
      console.log(practice);
      
      if (practice) {
        // const x = router.routeReuseStrategy.shouldReuseRoute;
        // router.routeReuseStrategy.shouldReuseRoute = () => false;
        // setTimeout(() => {
        //   router.routeReuseStrategy.shouldReuseRoute = x;
        // }, 1000);
        if (practice.status === 0) {
          this.startPracticeIELTS(lesson.idRef).subscribe((res: any) => {
            this.startLessonEvent();

              if (res.status === 1) {
                console.log(12321321312);
                
                router.navigate(
                  [
                    '/learn',
                    lesson.idUnit,
                    'lesson-practice',
                    practice.typePractice,
                    lesson.idRef,
                  ],
                  {
                    queryParams: { lessonId: lesson.id },
                  }
                );
              } else {
                this.toastService.warning('Không thể bắt đầu bài luyện!');
              }
          });
          return;
        }
        if (practice.status === 1) {
          router.navigate(
            [
              '/learn',
              lesson.idUnit,
              'lesson-practice',
              practice.typePractice,
              lesson.idRef,
            ],
            {
              queryParams: { lessonId: lesson.id },
            }
          );

          return;
        }
        if (practice.status === 2 || practice.status === 7) {
          router.navigate(
            [
              '/learn',
              lesson.idUnit,
              'lesson-practice',
              practice.typePractice,
              lesson.idRef,
              'results'
            ],
            {
              queryParams: { lessonId: lesson.id},
            }
          );

          return;
        }
      }
    });
  }
  endPractice(body: any) {
    return this._http.put(environment.BASE_API_URL + CONSTANTS.API.PRACTICE.PUT_SUBMIT, body, { headers: this.myHeader })
        .pipe(
            tap(() => {

            }),
            catchError(this.httpErrorHandler.handleError)
        );
}
  exitLearn(router: Router) {
    const currentIndexLesson = this.listLesson?.findIndex(
      (l: any) => l.id === this.lessonId
    );
    if(this.listLesson[currentIndexLesson]) {
      this.mixpanelService.track(MIXPANEL.LEARN.name, {
        action: MIXPANEL.LEARN.actions.LEARN_EXIT,
        id_topic: this.listLesson[currentIndexLesson].idUnit,
        screen_name: 'Learn',
        lesson_type: this.listLesson[currentIndexLesson].typeAction,
        id_lesson: this.listLesson[currentIndexLesson].id

      });
      router.navigate([`/learn`]);
    } else {
      router.navigate([`/learn`]);
    }
   
    

  }
  nextLesson(router: Router) {

    const currentIndexLesson = this.listLesson?.findIndex(
      (l: any) => l.id === this.lessonId
    );

    if (
      currentIndexLesson > -1 &&
      currentIndexLesson < this.listLesson?.length - 1
    ) {
      console.log(this.listLesson[currentIndexLesson + 1]);
      
      this.mixpanelService.track(MIXPANEL.LEARN.name, {
        action: MIXPANEL.LEARN.actions.LEARN_NEXT,
        id_topic: this.listLesson[currentIndexLesson + 1].idUnit,
        screen_name: 'Learn',
        lesson_type: this.listLesson[currentIndexLesson + 1].typeAction,
        id_lesson: this.listLesson[currentIndexLesson + 1].id

      });
      this.goToLesson(this.listLesson[currentIndexLesson + 1], router);
      return;
    }
    return false;
  }
  canNext() {
    this.lessonId =
      this.lessonId || this.activatedRoute.snapshot.queryParams['lessonId'];
    const currentIndexLesson = this.listLesson?.findIndex(
      (l: any) => l.id === this.lessonId
    );          
    if (
      currentIndexLesson > -1 &&
      currentIndexLesson < this.listLesson?.length - 1
    ) {
      return true;
    }
    return false;
  }
  goToLesson(lesson: any, router: Router) {

    this.lessonId = lesson.id;
    // const retry = (lesson: any) => {

    //   this.startExam(lesson.idRef).subscribe((res: any) => {
    //     if (res.status === 1) {
    //       router.navigate(['/learn', lesson.idUnit, 'lesson-exam', lesson.idRef], {
    //         queryParams: { lessonId: lesson.id }, replaceUrl: true,

    //       });
    //       // this.router.navigate(['/exam/1/detail', idConfig, 'view', '1'], { relativeTo: this.activatedRoute });

    //       // this.router.navigate(['/exam/1/detail', idConfig, 'view', '1'], { relativeTo: this.activatedRoute });
    //     } else {
    //       this.toastService.warning(res.message);
    //     }

    //   })
    // }
    // const continueExam = (lesson: any) => {
    //   router.navigate(['/learn', lesson.idUnit, 'lesson-exam', lesson.idRef], {
    //     queryParams: { lessonId: lesson.id }, replaceUrl: true,

    //   });

    // }
    if (lesson?.typeAction === EnumActionInLessonIeltsCourse.Video) {
      router.navigate(['/learn', lesson.idUnit, 'lesson-video'], {
        queryParams: {
          lessonId: lesson.id,
        },
        replaceUrl: true,
      });
      return;
    }

    if (lesson?.typeAction === EnumActionInLessonIeltsCourse.FlashCard) {
      router.navigate(['/learn', lesson.idUnit, 'lesson-video'], {
        queryParams: {
          lessonId: lesson.id,
        },
        replaceUrl: true,
      });
      return;
    }
    if (lesson?.typeAction === EnumActionInLessonIeltsCourse.PracticeIeltsVocabulary) {
      this.goToPractice(lesson, router);

      return;
    }
    if (lesson?.typeAction === EnumActionInLessonIeltsCourse.PracticeIeltsGrammar) {
      this.goToPractice(lesson, router);

      return;
    }
    if (lesson?.typeAction === EnumActionInLessonIeltsCourse.PracticeIeltsReading) {
      this.goToPractice(lesson, router);

      return;
    }
    if (lesson?.typeAction === EnumActionInLessonIeltsCourse.PracticeIeltsListening) {
      this.goToPractice(lesson, router);

      return;
    }
    if (lesson?.typeAction === EnumActionInLessonIeltsCourse.PracticeIeltsWriting) {
      this.goToPractice(lesson, router);

      return;
    }

  }
  finishLesson(body: {
    idCourse: 'string';
    idUnit: 'string';
    idLesson: 'string';
    type: 'string';
  }) {
    return this._http.put(
      environment.BASE_API_URL + CONSTANTS.API.COURSE.FINISH_LESSON,
      {
        idCourse: body.idCourse,
        idUnit: body.idUnit,
        idLesson: body.idLesson,
      }
    );
  }
  getCourseDetail(courseId: string) {
    return this._http.get(
      environment.BASE_API_URL + CONSTANTS.API.COURSE.COURSE_DETAIL + courseId,
      { headers: this.myHeader }
    );
  }
  getUnitCourse(unitId: string) {
    return this._http
      .get(environment.BASE_API_URL + CONSTANTS.API.COURSE.GET_UNIT + unitId, {
        headers: this.myHeader,
      })
      .pipe(
        tap((res: any) => {
          this.listLesson = res?.data?.lessons;
        })
      );
  }
  getLessonDetail(lessonId: string) {
    return this._http.get(
      environment.BASE_API_URL +
        CONSTANTS.API.COURSE.GET_LESSON_DETAIL +
        lessonId,
      { headers: this.myHeader }
    );
  }
  startPracticeIELTS(practiceId: any) {
    this.startLessonEvent();

    return this._http.post(
      environment.BASE_API_URL + CONSTANTS.API.PRACTICE.POST_START + practiceId,
      { idPractice: practiceId },
      { headers: this.myHeader }
    );
  }
  getContentMedia(idRef: string) {
    return this._http.get(
      environment.BASE_API_URL + CONSTANTS.API.COURSE.GET_CONTENT_MEDIA + idRef,
      { headers: this.myHeader }
    );
  }
  getContentTextbook(idRef: string) {
    return this._http.get(
      environment.BASE_API_URL +
        CONSTANTS.API.COURSE.GET_CONTENT_TEXTBOOK +
        idRef,
      { headers: this.myHeader }
    );
  }
  getPracticeCoreInfo(idPractice: string) {
    return this._http.get(
      environment.BASE_API_URL + CONSTANTS.API.PRACTICE_CORE.INFO + idPractice,
      { headers: this.myHeader }
    );
  }
  startPracticeCore(idPractice: string) {
    this.startLessonEvent();
    return this._http.get(
      environment.BASE_API_URL + CONSTANTS.API.PRACTICE_CORE.START + idPractice,
      { headers: this.myHeader }
    );
  }
  getPracticeStepData(practiceId: string, stepId: string) {
    return this._http.get(
      environment.BASE_API_URL +
        CONSTANTS.API.PRACTICE_CORE.STEP_DATA +
        practiceId +
        '/' +
        stepId,
      { headers: this.myHeader }
    );
  }
  getAnswerStep(stepId: string) {
    return this._http.get(
      environment.BASE_API_URL +
        CONSTANTS.API.PRACTICE_CORE.STEP_ANSWER +
        stepId,
      { headers: this.myHeader }
    );
  }
  sendAnswer(body: any) {
    return this._http.post(
      environment.BASE_API_URL + CONSTANTS.API.PRACTICE_CORE.SEND_ANSWER,
      body,
      { headers: this.myHeader }
    );
  }
  sendQuestion(body: any) {
    return this._http.post(
      environment.BASE_API_URL + CONSTANTS.API.PRACTICE_CORE.SEND_ANSWER,
      body,
      { headers: this.myHeader }
    );
  }
  startExam(idConfig: any) {
    return this._http.post(
      environment.BASE_API_URL + CONSTANTS.API.EXAM.START + idConfig,
      {
        idConfig: idConfig,
        startAppClient: 'Web',
      },
      {
        params: {
          codeApp: CONSTANTS.CODE_APP,
        },
        headers: this.myHeader,
      }
    );
  }
      getDetailIeltsPractice(practiceId: any) {
        return this._http.get(environment.BASE_API_URL + CONSTANTS.API.PRACTICE.GET_PRACTICE_DETAIL_BY_ID + practiceId, { headers: this.myHeader })
            .pipe(
                catchError(this.httpErrorHandler.handleError)
            );
    }

    getDetailDonePracticeIelts(practiceId: any) {
        return this._http.get(environment.BASE_API_URL + CONSTANTS.API.PRACTICE.GET_DETAIL_DONE + practiceId, { headers: this.myHeader })
            .pipe(
                catchError(this.httpErrorHandler.handleError)
            );
    }
    getDetailQuestionPracticeIelts(practiceId: any, stepId: any) {
        return this._http.get(environment.BASE_API_URL + CONSTANTS.API.PRACTICE.GET_DETAIL_QUESTION + practiceId + '/' + stepId, { headers: this.myHeader })
            .pipe(
                catchError(this.httpErrorHandler.handleError)
            );
    } 
}
