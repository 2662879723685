export const LISTMENU = [
    {
        name: 'TOPIK Master',
        path: '/',
        icon: 'home-icon.svg',
        icon_active: 'home-icon-active.svg',
        icon_mb: 'home-icon-mb.svg',

    },
    {
        name: 'Học bài',
        path: '/learn',
        icon: 'learn-icon.svg',
        icon_active: 'learn-icon-active.svg',
        icon_mb: 'learn-icon-mb.svg',

    },
    {
        name: 'Báo cáo',
        path: '/report',
        icon: 'report-icon.svg',
        icon_active: 'report-icon-active.svg',
        icon_mb: 'report-icon-mb.svg',

    },

]
