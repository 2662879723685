import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { forkJoin, tap } from 'rxjs';
import { RightSideService } from './right-side.service';

@Injectable()
export class RightSideResolveService  implements Resolve<boolean> {
  constructor(private rightSideService: RightSideService) { }
  resolve(): any {
   return forkJoin([
    this.rightSideService.getChartData(),
   ]).pipe(tap(console.log))
  }
}
