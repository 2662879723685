import { inject } from '@angular/core';
import { Router } from '@angular/router';
import {JwtHelperService } from '@auth0/angular-jwt';
export function setToken(token: string) {
  localStorage.setItem('token', token);
  getUserInfo();
}
export function decodeUserTraits(access_token: any) {
  if (access_token) {
      return decodeURIComponent(window.atob(access_token));
  }
  return '';
}
export function getUserInfo() {
  const JWT: JwtHelperService = inject(JwtHelperService);
  const userInfo = JWT.decodeToken(getToken());
  if (userInfo) {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    return userInfo;
  }
  logOut();
  return;
}
export function logOut() {
  // clear token
  // localStorage.clear();
  localStorage.removeItem('token');
  localStorage.removeItem('avatar');
  localStorage.removeItem('lastUrl');
  localStorage.removeItem('user_traits');
  localStorage.removeItem('current-course');
  localStorage.removeItem('current-target');

  inject(Router).navigate(['/login']);
  return;
}
export function getUserInfoStraits() {
  const userInfo = getUserStraits();
  return userInfo;
}
export function getUserStraits() {
  const userStraits = localStorage.getItem('user_traits');
  if(userStraits){
      return JSON.parse(decodeUserTraits(localStorage.getItem('user_traits')));

  }
  return null;
}
export function  getAvatar(): string {
    return getUserInfo().avatar ?? 'assets/img/avatars/default.svg';
} 
export function getToken(): any  {
    const token = localStorage.getItem('token');
    if (token) {
        return token;
    }
    logOut();
}
