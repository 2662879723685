import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { OAuthService } from '../../auth/auth.service';


@Injectable()
export class HttpErrorHandler {
  constructor(private oAuthService: OAuthService) { }
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}`);
      console.error(
        `${error.message}`);
      console.error(error.error);
      if (error.status === 401) {
        // clear token
        localStorage.setItem('last_url', window.location.href);
        this.oAuthService.logOut();
      }
    }
    // return an ErrorObservable with a user-facing error message
    return throwError(error);
  }
}
