<div class="container-sidebar">
    <div class="top-content">
        <div class="header-sidebar">
            <div class="logo">
                <img src="assets/common/logo-app.svg" alt="">
            </div>
        </div>
        <div class="list-menu">
            <div class="item" *ngFor="let item of listMenu" routerLinkActive="active" [routerLink]="[item.path]"
                [routerLinkActiveOptions]="{ exact: true }">
                <div class="icon">
                    <img src="assets/sidebar/{{item.icon}}" alt="">
                </div>
                <div class="icon-active">
                    <img src="assets/sidebar/{{item.icon_active}}" alt="">
                </div>
                <div class="name">
                    {{item.name}}
                </div>
            </div>
        </div>
    </div>
    <div class="bottom-sidebar">
        <div class="content-bottom">
            <div class="img">
                <img src="assets/sidebar/img-bottom.svg" alt="">
            </div>
            <div class="text">
                Nhập mã sách để mở khóa học độc quyền
            </div>
            <div class="btn" (click)="openpopup()">
                <button>
                    <img src="assets/sidebar/unlock-icon.svg" alt="">
                    Mở khóa sách
                </button>
            </div>

        </div>
    </div>
</div>
<div class="container-sidebar-mb">
    <div class="item" *ngFor="let item of listMenu" routerLinkActive="active" [routerLink]="[item.path]" [routerLinkActiveOptions]="{ exact: true }"> 
        <div class="color"></div>
        <div class="icon">
            <img src="assets/sidebar/{{item.icon_mb}}" alt="">
        </div>
        <div class="icon-active">
            <img src="assets/sidebar/{{item.icon}}" alt="">
        </div>
        <div class="name">
            {{item.name}}
        </div>
    </div>
</div>
