import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONSTANTS, environment } from '../../constants/constants';
import { Subject, catchError } from 'rxjs';
import { HttpErrorHandler } from '../../common/error/HttpErrorResponse';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  myHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  private avatar = new Subject<string>();
  avatar$ = this.avatar.asObservable();
  private profileInfo = new Subject<string>();
  profileInfo$ = this.profileInfo.asObservable();

  constructor(
    private _http: HttpClient,
    private httpErrorHandler: HttpErrorHandler
  ) { }

  // Update profile
  changeProfile(userInfo: any) {
    return this._http.post(environment.AUTH_SERVER + CONSTANTS.OAUTH.PROFILE, userInfo, { headers: this.myHeader })
      .pipe(
        catchError(this.httpErrorHandler.handleError)
      );
  }


  profileChanged(val: string) {
    this.profileInfo.next(val);
  }
  changeProfileAvatar(data: any) {
      return this._http.post(environment.AUTH_SERVER + 'api/account/profile/avatar', data, { headers: this.myHeader })
          .pipe(
              catchError(this.httpErrorHandler.handleError)
          );
  }
}
