import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CONSTANTS, environment } from '../constants/constants';
@Injectable({
    providedIn: 'root'
})
export class OAuthService {
    myHeader = new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    });
    constructor(
        private jwtHelper: JwtHelperService,
        private router: Router,
        private _http: HttpClient,
    
    ) { }

    setToken(token: string) {
        localStorage.setItem('token', token);
        this.getUserInfo();
    }
    getToken(): any {
        const token = localStorage.getItem('token');
        if (token) {
            return token;
        }
        this.logOut();
    }
    refresh() {
        return this._http.post(environment.AUTH_SERVER + CONSTANTS.OAUTH.REFRESH_TOKEN, {}, { headers: this.myHeader });
    }
    getUserInfo() {
        const userInfo = this.jwtHelper.decodeToken(this.getToken());
        if (userInfo) {
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
            return userInfo;
        }
        this.logOut();
        return;
    }
    getAvatar(): string {
        return this.getUserInfo().avatar ?? 'assets/img/avatars/default.svg';
    }
    logOut() {
        // clear token
        localStorage.removeItem('token');
        localStorage.removeItem('avatar');
        localStorage.removeItem('lastUrl');
        localStorage.removeItem('user_traits');
        localStorage.removeItem('current-course');
        localStorage.removeItem('current-target');

        this.router.navigate(['/login']);
        return;
    }
    updateMixpanel(idMixpanel: string) {
        const dataJson = JSON.stringify({ idMixpanel });
        return this._http.post<any>(environment.AUTH_SERVER + CONSTANTS.OAUTH.UPDATE_MIXPANEL, dataJson, { headers: this.myHeader });
    }
}
