<div class="container-popup">
    <div class="step">
        <div class="close-modal" (click)="close()">
            <img src="assets/common/x-icon-v2.svg" alt="">
        </div>
        <div class="content-step">
            <div class="title">
                Mở khóa sách
            </div>
            <div class="des">
                Nhập mã code của quyển sách TOPIK Master trong tay bạn
                để mở khóa học độc quyền.
            </div>
            <div class="active-input">
                <ng-container *ngFor="let input of listInput,let index = index ">
                    <input type="text" class="code" maxlength="4" placeholder="xxxx" [(ngModel)]="input.value"
                        (ngModelChange)="detectInput($event)" [id]="input.id" (keydown)="onKeydownMain($event,index)"
                        (paste)="onPaste($event)">
                    <span class="separate" *ngIf="index < listInput.length - 1">-</span>
                </ng-container>
            </div>
            <div class="verify-cap">
                <app-re-captcha language="vn" #recaptcha (captchaResponse)="handleCorrectCaptcha($event)"
                    [site_key]="captcha">
                </app-re-captcha>
            </div>
            <div class="text-danger" *ngIf="textError">
                <p>{{textError}}</p>
            </div>
        </div>
        <div class="btn">
            <button (click)="submit()">
                Mở khóa
            </button>
        </div>
    </div>
</div>