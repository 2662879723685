<div class="background-img-upload" (click)="exit()"></div>
<div class="upload-image-popup">
    <div class="upload-container">
        <image-cropper [imageChangedEvent]="eventUpload" [maintainAspectRatio]="true" [aspectRatio]="1 / 1" output="base64"
            [resizeToWidth]="256" [resizeToHeight]="256" format="png" (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
        </image-cropper>
    </div>
    <div class="btn-container">
        <button type="button" class="btn danger" (click)="exit()">
            Hủy
        </button>
        <button type="button" class="btn primary" #submitBtn (click)="saveImageFromCroppie()">
            Cập nhật
        </button>
    </div>
</div>