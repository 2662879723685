import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { JwtModule } from '@auth0/angular-jwt';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { UnauthorizedInterceptor } from './auth/interceptors/unauthorized-interceptor.service';
import { LoadingInterceptor } from './auth/interceptors/loading-interceptor.service';
import { HttpErrorHandler } from './common/error/HttpErrorResponse';
import { RefreshTokenInterceptor } from './auth/interceptors/refresh-token-interceptor.service';

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
   
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
        importProvidersFrom(),
    importProvidersFrom(
      JwtModule.forRoot({
        config: {},
      }),
      ToastrModule.forRoot({
        positionClass: 'toast-top-right',
      })
    ),

    HttpErrorHandler,
  ],
};
