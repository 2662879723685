import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mergeMap, map, catchError, of } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SendErrorService {
  uploadHeader = new HttpHeaders({ 'Content-Type': 'image/jpeg' });
  myHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  constructor(private http: HttpClient) { }
  uploadPdfAssignmentFile(questionNumber: any, file: any) {
    return this.getPresignReportQuestion(questionNumber)
      .pipe(mergeMap((preSigned: any) => {

        return this.uploadFile(this.b64toBlob(file), preSigned.preSignedUrl).pipe(
          map(val => {
            return {
              dataS3: val,
              preSigned: preSigned
            }
          }));
      }))
  }

  getPresignReportQuestion(questionNumber: any) {
    return this.http.get(environment.BASE_API_URL + 'question/pre-signed-url/report/' + questionNumber, { headers: this.myHeader })
      .pipe(

    );
  }

  uploadFile(file: any, presignedUrl: any) {
    return this.http.put(presignedUrl,
      file,
      { headers: this.uploadHeader })
      .pipe(
        catchError(() => {
          return of({})

        })
      );
  }

  b64toBlob(dataURI: any) {
    if (!dataURI) {
      return;
    }
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const _return = new Blob([ab], { type: 'image/jpeg' })
    return _return;
  }
  sendQuestionReport(reportData: any) {
    const dataJson = JSON.stringify(reportData);
    return this.http.post(environment.BASE_API_URL + 'question/report', dataJson, { headers: this.myHeader })
      .pipe(

      );
  }
  postQuestionReport(data: any) {
    return this.http.post(environment.BASE_API_URL + 'api/error-report/pusherror', data, { headers: this.myHeader })
      .pipe();
  }
}

