<div class="confirm-container" *ngIf="infoConfirm">
    <div class="confirm-owl">
        <img [src]="infoConfirm.img" alt="">
    </div>
    <div class="confirm">
        <div class="confirm-content" [ngStyle]="infoConfirm?.styleContent">
            <div class="title"  [ngStyle]="infoConfirm?.styleTitle">
                {{infoConfirm.title}}
            </div>
            <div class="content" [innerHtml]="infoConfirm.content"></div>
           
        </div>
        <div class="confirm-footer">
            <button class="btn btn-cancel" *ngIf="!infoConfirm.refuseEvent && infoConfirm.showCancel" (click)="cancel()" [ngStyle]="{backgroundColor: infoConfirm?.colorCancel}">
                {{infoConfirm.nameCancel || 'Bỏ qua'}}</button>
            <button class="btn btn-accept" (click)="accept()">
                {{infoConfirm.nameAccept || 'Đồng ý'}}</button>
            <button class="btn btn-refuse"  [ngStyle]="infoConfirm?.styleRefuseBtn" *ngIf="infoConfirm.refuseEvent" (click)="refuse()">
                {{infoConfirm?.nameRefuse || 'Từ chối'}}</button>
           
        </div>
    </div>
    
</div>