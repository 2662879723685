export const base_api_url = 'https://api-elb.onluyen.vn/';
export const base_auth_server = 'https://oauth.onluyen.vn/';
export const base_api_game = 'https://serverless.onluyen.vn/api-quizz/';
export const base_api_hunt_quizz = 'https://serverless.onluyen.vn/api-hunt-quizz/';
export const base_api_log_dev = 'https://serverless.onluyen.vn/error-content/api/';
export const thanh_toan = 'https://thanhtoan.onluyen.vn/api/';
export const open_app = 'web';
export const captcha_key = '6LeHcYopAAAAAMaClnrvmqUmxhngS8qNrBRmE6TL';
export const mixpanel_token = '5a94e707feff4f23f3e39008e3d2b659';
export const environment = {
  BASE_API_URL: base_api_url,
  AUTH: base_auth_server,
  OPEN_APP: open_app,
  MIXPANEL_TOKEN: mixpanel_token

};
