import { Component, EventEmitter, Output } from '@angular/core';
import { MODAL_ID } from '../../../../common/popup/modal-common/modal-id.const';
import { LessonService } from '../../../../components/learn/lesson.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalCommonService } from '../../../../common/popup/modal-common/modal-common.service';
import { ConfirmModal } from '../../../../common/popup/confirm-modal/confirm-modal.model';
import { EnumActionInLessonIeltsCourse } from '../../../../components/learn/lesson.enum';
import { MIXPANEL } from '../../../../common/mixpanel/mixpanel.constant';
import { MixpanelService } from '../../../../common/mixpanel/mixpanel.service';

@Component({
  selector: 'app-lesson-continue',
  standalone: true,
  imports: [],
  templateUrl: './lesson-continue.component.html',
  styleUrl: './lesson-continue.component.scss'
})
export class LessonContinueComponent {
  @Output() data = new EventEmitter();
  idModalUpgrade = MODAL_ID.COMMON.UPGRADE;
  listLesson: any = [];
  selectedUnit: any;
  courseId: any;
  currentLesson: any;
  constructor(
    private lessonService: LessonService,
    private router: Router,
    private modalService: ModalCommonService,
    private activatedRoute: ActivatedRoute,
    private mixpanelService: MixpanelService

  ) {

  }
  ngOnInit(): void {
    this.courseId = localStorage.getItem('current-course');
    if (this.courseId) {
      this.getListLesson(this.courseId);
    } else {
      this.callLesson();
    }
  }
  continuesLesson() {
    this.mixpanelService.track(MIXPANEL.CONTINUE_STUDY.name, {
      action: MIXPANEL.CONTINUE_STUDY.actions.CONTINUE_STUDY,
      screen_name: 'Learn',
    });
    this.gotoLesson(this.currentLesson, true);

  }
  callLesson() {
    setTimeout(() => {
      this.courseId = localStorage.getItem('current-course');
      if (!this.courseId) {
        this.callLesson();
      } else {
        this.getListLesson(this.courseId);
      }
    }, 500)
  }

  gotoLesson(lesson: any, check: any) {
    if (lesson.isAccess || check) {
      this.lessonService.goToLesson(lesson, this.router)
    } else {
      const dataConfirm: ConfirmModal = {
        nameAccept: 'Đăng kí',
        nameCancel: 'Exit',
        nameRefuse: 'Để sau',
        title: 'Bạn chưa mua khóa học',
        content: 'Vui lòng đăng kí khóa học để có thể xem nội dung bài học nhé',
        styleContent: {
          width: '420px'
        },
        styleRefuseBtn: {
          background: '#fff',
          color: '#0030c3',
          border: '1px solid'
        },
        refuseEvent: () => {
          this.modalService.closeModal(this.idModalUpgrade);
        },
        okEvent: () => {
          // window.location.href = 'https://edmicro.edu.vn/khoa-hoc#tuvan'
        },
        styleTitle: {}
      }
      this.modalService.openModal(this.idModalUpgrade, dataConfirm);
    }
  }
  getListLesson(courseId: string) {
    this.lessonService.getCourseDetail(courseId).subscribe((res: any) => {
      if (res?.data) {
        this.currentLesson = res?.data?.currentLesson;
        this.data.emit(this.currentLesson)
        if (res && res?.data.units?.length) {
          this.listLesson = res.data.units;
          const currentUnit = this.listLesson.find((l: any) => {
            return l.id === this.currentLesson?.idUnit
          })
          if (currentUnit && this.activatedRoute.snapshot.queryParams['expand']) {
            this.clickItemEvent(currentUnit);
            if (window.innerWidth > 1116) {
              setTimeout(() => {
                const qElement = document.querySelector('#lesson-' + this.currentLesson.id);
                if (qElement) {
                  qElement?.scrollIntoView({ behavior: 'smooth' });
                }
              }, 1000)
            }
          }
        }
      }
    });
  }
  clickItemEvent(item: any) {
    if (this.selectedUnit?.id === item?.id) {
      this.selectedUnit = null;
      return;
    }
    this.selectedUnit = item;
    this.getUnit(item);
  }


  getUnit(item: any) {
    this.lessonService.getUnitCourse(item.id).subscribe((res: any) => {
      if (res && res?.data.lessons?.length) {
        item.lessons = res.data.lessons?.map((l: any) => {
          let nameType = 'Exercise';
          if (l?.typeAction === EnumActionInLessonIeltsCourse.Video) {
            nameType = 'Video';
          }
          // if (l?.typeAction === EnumActionInLessonIeltsCourse.Mathigon) {
          //   nameType = 'Summary';
          // }
          // if (l?.typeAction === EnumActionInLessonIeltsCourse.PracticeCore) {
          //   nameType = 'Dictation';
          // }
          if (l?.typeAction === EnumActionInLessonIeltsCourse.FlashCard) {
            nameType = 'Flashcard';
          }
          // if (l?.typeAction === EnumActionInLessonIeltsCourse.PDF) {
          //   nameType = 'Document';
          // }
          return { ...l, nameType: nameType, isAccess: res?.data.isAccess }
        });
      }
    });
  }
}
