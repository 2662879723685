import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class PopupOpenBookService {

  
  myHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    private http: HttpClient,
  ) { }

  addPackageByPromocode(promoData: any) {

    const dataJson = JSON.stringify(promoData);
    return this.http.post(environment.THANH_TOAN + 'package/active-code', dataJson, { headers: this.myHeader })
     ;
  }
}
