import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalCommonComponent } from "../../../common/popup/modal-common/modal-common.component";
import { ListChangeService } from './list-change.service'
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MixpanelService } from '../../../common/mixpanel/mixpanel.service';
import { MIXPANEL } from '../../../common/mixpanel/mixpanel.constant';


@Component({
  selector: 'app-list-change-course',
  standalone: true,
  templateUrl: './list-change-course.component.html',
  styleUrl: './list-change-course.component.scss',
  imports: [ModalCommonComponent],
  providers: [ListChangeService],

})
export class ListChangeCourseComponent implements OnInit {
  @Output() save = new EventEmitter<boolean>()
  listCourse: any = [];
  currentCourse: any;
  constructor(
    private listChangeService: ListChangeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private mixpanelService: MixpanelService

  ) { }

  ngOnInit() {
    this.getListCourse();

  }
  getListCourse() {
    this.listChangeService.getListCourse().subscribe((res: any) => {
        if(res?.data?.length) {
          this.listCourse = res?.data;
      this.currentCourse = res.data.filter((x: any) => x.isCurrent)[0];
      console.log(this.currentCourse);
      
        }
    });
  }

  OpenUnlockBooks(event: string, course : any) {
    switch (event) {
      case 'start':
        this.listChangeService.postChangeCourse(course.id).subscribe((res: any) => {
          if (res?.status === 1) {
            this.getListCourse();
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            localStorage.setItem('current-course', course.id);
            localStorage.setItem('current-target', course.target);
            this.mixpanelService.track(MIXPANEL.CHANGE_COURSE.name, {
              action: MIXPANEL.CHANGE_COURSE.actions.CHOOSE_COURSE,
              screen_name: 'Profile',
              id_change_course: course.id,
              id_current_course: this.currentCourse.id
            });
            this.router.navigate(['./'], { relativeTo: this.activatedRoute});
            this.router.onSameUrlNavigation = 'ignore';
          } else {
            this.toastrService.warning(res.message)
          }
        });
        break;
      case 'buy':
          this.save.emit(course);
        break;
      default:
        break;
    }

  } 
}
