
import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpEvent, HttpHandler, HttpInterceptor, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OAuthService } from '../auth.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  processing = false;
  constructor(
    private injector: Injector,
    private oAuthService: OAuthService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('translate.googleapis.com') >= 0 || request.url.indexOf('api.tracau.vn') >= 0) {
      return next.handle(request);

    }
    if (request.url.indexOf('.json') >= 0) {
      return next.handle(request);

    }
    if (!this.processing) {
      const userInfo = this.oAuthService.getUserInfo();
      if (userInfo) {
        this.processing = true;
        const timeRemain = (userInfo.exp - userInfo.iat);
        const HALF_HOUR = 7200; // 2 hour
        if (timeRemain < HALF_HOUR) {
          const http = this.injector.get(HttpClient);
          this.oAuthService.refresh().subscribe((res: any) => {
            // save token
            if (res['access_token']) {
              this.oAuthService.setToken(res['access_token']);
              this.processing = false;
            }
          });
        }
      }
    }
    return next.handle(request);
  }
}
