import { Routes } from '@angular/router';
import { FullLayoutComponent } from './layout/full-layout/full-layout.component';
import { ProfileComponent } from './components/profile/profile.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth-guard.service';
import { RightSideResolveService } from './layout/full-layout/right-side/right-side-resolve.service';
export const routes: Routes = [
    {
        path: '',
        component: FullLayoutComponent,
        canActivate: [AuthGuard],
        providers: [RightSideResolveService],
        resolve: {
                  dataChart: RightSideResolveService,
                },
        children: [
            // {
            //     path: '',
            //     redirectTo: '/home',
            //     pathMatch: 'full'
            // },
            {
                path: '',
                loadChildren: () => import('./components/home/home-router').then(mod => mod.HOME_ROUTER),
            },
            {
                path: 'exam',
                loadChildren: () => import('./components/exam-layout/exam-router').then(mod => mod.EXAM_ROUTER),
                data: {
                    fullScreen: true,
                }
            },
            {
                path: 'learn',
                loadChildren: () => import('./components/learn/learn-router').then(mod => mod.LEARN_ROUTER),
                
            },
            {
                path: 'profile',
                component: ProfileComponent,
                data: {
                    isRightSide: false,
                    backUrl: '/'
                }
            },
            {
                path: 'report',
                loadChildren: () => import('./components/report/report-router').then(mod => mod.REPORT_ROUTER),
                data: {
                    isRightSide: false,
                    // backUrl: '/'
                },
            },
        ]
    },

    {
        path: 'login',
        component: LoginComponent,
    },
];
