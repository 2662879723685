import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { QuickReportComponent } from './quick-report/quick-report.component';
import { RightSideService } from './right-side.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ListChangeService } from '../../../components/profile/list-change-course/list-change.service';
import { filter } from 'rxjs';
import { ReportLearnComponent } from './report-learn/report-learn.component';
import { LessonContinueComponent } from './lesson-continue/lesson-continue.component';
import { MixpanelService } from '../../../common/mixpanel/mixpanel.service';
import { MIXPANEL } from '../../../common/mixpanel/mixpanel.constant';

@Component({
  selector: 'app-right-side',
  standalone: true,
  imports: [CommonModule, QuickReportComponent, ReportLearnComponent,LessonContinueComponent],
  providers: [RightSideService,
    ListChangeService,
  ],
  templateUrl: './right-side.component.html',
  styleUrl: './right-side.component.scss',
})
export class RightSideComponent implements OnInit {
  listCourse: any = [];
  listAccess: any = [];
  image = 'assets/common/course-thumbnail.svg';
  currentCourse: any;
  showCourse = false;
  isLearn = false;
  constructor(
    private rightSideService: RightSideService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private listChangeService: ListChangeService,
    private mixpanelService: MixpanelService
  ) { }
  ngOnInit() {
    this.getListCourse();
    this.router.url === '/learn'
    ? (this.isLearn = true)
    : (this.isLearn = false);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.router.url === '/learn'
          ? (this.isLearn = true)
          : (this.isLearn = false);

      });
  }

  getListCourse() {
    this.rightSideService.getListCourse().subscribe((res: any) => {
      this.listCourse = res?.data;
      const isTrial = this.listCourse?.every((l: any) => {
        return l.isTrial;
      });
      this.currentCourse = this.listCourse.find((item: any) => item.isCurrent);
      const accessCourse = this.listCourse.find((item: any) => item.isAccess);
      this.listAccess = this.listCourse.sort((a: any, b: any) => {
        if (a.isAccess && !b.isAccess) {
          return -1;
        } else if (!a.isAccess && b.isAccess) {
          return 1;
        } else {
          return 0;
        }
      })
      if (this.currentCourse) {
        if (localStorage.getItem('current-course') && localStorage.getItem('current-course') !== this.currentCourse.id) {
          localStorage.setItem('current-course', this.currentCourse.id);
          localStorage.setItem('current-target', this.currentCourse.target);

          location.reload();
        }
        localStorage.setItem('current-course', this.currentCourse.id);
        localStorage.setItem('current-target', this.currentCourse.target);
        this.rightSideService.setCurrentCourse(this.currentCourse);
      } else {
        if (accessCourse) {
          if (!isTrial) {
            this.goCourse('start-first', accessCourse);
          }
        } else {
          // alert('Bạn chưa mua khóa học.\nVui lòng mở khóa sách để hiện nội dung khóa học.');
        }
      }

      // const isExpire = this.listData.every(l=> {
      //   return !l.isAccess && !l.isTrial
      // })
      // const userInfo = this.oAuthService.getUserInfo();
      // const timeLogin = localStorage.getItem('timeFirstLogin' + userInfo.userId);
      // if (!timeLogin && isTrial) {

      //   const dataConfirm: ConfirmModal = {
      //     nameAccept: 'Học thử',
      //     nameCancel: '',
      //     nameRefuse: 'Mua khóa học',
      //     // styleRefuseBtn: { 'display': 'none' },
      //     title: 'Edmicro tặng bạn \n khóa học thử Edmicro IELTS',
      //     content: `    <div class="p-3  fs-4 fst-normal ">
      //         Cùng thử nhé!
      //     </div>`,
      //     styleContent: {
      //       'width': '450px', 'fontSize': '18px', 'padding': '16px', 'fontStyle': 'normal !important'
      //     },
      //     refuseEvent: () => {
      //       window.open('https://edmicro.edu.vn/khoa-hoc#step4', '_blank')
      //       this.modalService.closeModal(this.idModal)
      //       this.goCourse('start-first', accessCourse)

      //     },
      //     okEvent: () => {
      //       this.goCourse('start-first', accessCourse)

      //       this.modalService.closeModal(this.idModal)

      //     },
      //   }
      //   this.modalService.openModal(this.idModal, dataConfirm);
      //   localStorage.setItem('timeFirstLogin' + userInfo.userId, new Date().getTime().toString());

      // }
    });
  }
  dropCourse() {
    if(this.isLearn) {
      this.mixpanelService.track(MIXPANEL.CHANGE_COURSE.name, {
        action: MIXPANEL.CHANGE_COURSE.actions.CHANGE_COURSE,
        screen_name: 'Learn',
    
      });
    } else {
      this.mixpanelService.track(MIXPANEL.CHANGE_COURSE.name, {
        action: MIXPANEL.CHANGE_COURSE.actions.CHANGE_COURSE,
        screen_name: 'Topik_Master',
      });
    }
    this.showCourse = !this.showCourse;
  }
  OpenUnlockBooks(event: string, course: any) {
    switch (event) {
      case 'start':
        this.listChangeService.postChangeCourse(course.id).subscribe((res: any) => {
          if (res?.status === 1) {
            this.getListCourse();
            // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            // this.router.onSameUrlNavigation = 'reload';
            localStorage.setItem('current-course', course.id);
            localStorage.setItem('current-target', course.target);
            if(this.isLearn) {
              this.mixpanelService.track(MIXPANEL.CHANGE_COURSE.name, {
                action: MIXPANEL.CHANGE_COURSE.actions.CHOOSE_COURSE,
                screen_name: 'Learn',
                id_change_course: course.id,
                id_current_course: this.currentCourse.id
              });
            } else {
              this.mixpanelService.track(MIXPANEL.CHANGE_COURSE.name, {
                action: MIXPANEL.CHANGE_COURSE.actions.CHOOSE_COURSE,
                screen_name: 'Topik_Master',
                id_change_course: course.id,
                id_current_course: this.currentCourse.id
              });
            }
            window.location.reload()
          } else {
            this.toastrService.warning(res.message)
          }
        });
        break;
      default:
        break;
    }

  }
  goCourse(event: string, course: any) {
    switch (event) {
      case 'start-first':
        this.rightSideService.postChangeCourse(course.id).subscribe((res: any) => {
          if (res?.status === 1) {
            location.reload();

          } else {
            alert(res.message);
          }
        });
        break;

      case 'start':
      case 'continues':
        this.rightSideService.postChangeCourse(course.id).subscribe((res: any) => {
          if (res?.status === 1) {
            this.getListCourse();
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            localStorage.setItem('current-course', course.id);
            localStorage.setItem('current-target', course.target);
            this.router.navigate(['./'],);
            this.router.onSameUrlNavigation = 'ignore';
          } else {
            this.toastrService.warning(res.message)
          }
        });

        break;
      case 'buy':
        if (course.links) {
          window.open(course.links, "_self");

        } else {
          window.open("https://edmicro.edu.vn/lo-trinh", "_self");

        }
        break;
      default:
        break;
    }

  }
}
