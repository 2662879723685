import { Component, ElementRef, ViewChild } from '@angular/core';
import { ReCaptchaComponent } from '../../../../components/re-captcha/re-captcha.component';
import { environment } from '../../../../constants/constants';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ModalCommonService } from '../../../../common/popup/modal-common/modal-common.service';
import { MODAL_ID } from '../../../../common/popup/modal-common/modal-id.const';
import { PopupOpenBookService } from './popup-openbook.service';
import { reloadComponent } from '../../../../common/function/reloadComponent';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { ListChangeService } from '../../../../components/profile/list-change-course/list-change.service';


@Component({
  selector: 'app-popup-openbook',
  standalone: true,
  imports: [FormsModule, ReCaptchaComponent, CommonModule],
  templateUrl: './popup-openbook.component.html',
  styleUrl: './popup-openbook.component.scss'
})
export class PopupOpenbookComponent {
  textError!: string;
  promocode!: string;
  status!: boolean;
  token: any;
  isDone!: boolean;
  captcha = environment.CAPTCHA_KEY;
  idOpenBook = MODAL_ID.COMMON.OPENBOOK;
  listInput = [
    {
      id: 'id1',
      value: '',
    },
    {
      id: 'id2',
      value: '',
    },
    {
      id: 'id3',
      value: '',
    },
    {
      id: 'id4',
      value: '',
    }
  ]
  // listCourse: any = [];

  @ViewChild('recaptcha') captchaComponent!: ReCaptchaComponent;
  constructor(
    private popupOpenBookService: PopupOpenBookService,
    private modalCommonService: ModalCommonService,
    private elementRef: ElementRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    // private listChangeService: ListChangeService,

  ) {

  }
  ngOnInit(
  ) {
    // this.getListCourse()
  }

  submit() {
    this.promocode = this.listInput.reduce((a, b) => a + b.value, "");
    this.textError = '';

    if (!this.promocode) {
      this.textError = 'Vui lòng nhập mã!';
      this.status = false;
      return;
    }
    if (!this.token) {
      this.textError = 'Vui lòng nhập xác nhận không phải người máy!';
      this.status = false;
      return;
    }
    const promoData = {
      code: this.promocode,
      recaptchaToken: this.token
    };

    this.popupOpenBookService.addPackageByPromocode(promoData).subscribe((data: any) => {
      this.textError = ''
      if (data && data.status === 1) {
        this.toastrService.success('Bạn đã kích hoạt thành công khóa học');
        this.status = true;
        this.isDone = true;
        // const courseActive = this.listCourse.filter((x: any) => x.name === data.packageUser.title)[0]
        // this.listChangeService.postChangeCourse(courseActive.id).subscribe((res: any) => {
        //   localStorage.setItem('current-course', courseActive.id);
        //   localStorage.setItem('current-target', courseActive.target);
        // })
        this.modalCommonService.closeModal(this.idOpenBook)
        reloadComponent(this.router, this.activatedRoute);
      } else if (data && data.status === 0) {
        this.captchaComponent.reset();
        this.promocode = '';
        this.textError = data.message;
        this.status = false;
      }
    });
  }

  detectInput(event: any) {
    if (event.length === 4) {
      this.listInput.every((input) => {
        if (input.value.length < 4) {
          this.elementRef.nativeElement.querySelector("#" + input.id)?.focus();
          return;
        }
        return true;
      })
    }
  }

  onKeydownMain(event: any, index: any) {
    if (!(event.target.value.length == 0) || !(event.key === "Backspace")) {
      return;
    }
    if (index > 0) {
      this.elementRef.nativeElement.querySelector("#id" + index).focus()
    } else {
      this.listInput.forEach(a => {
        if (a.value.length != 0) {
          this.elementRef.nativeElement.querySelector("#" + a.id).focus();
        }
      })
    }
  }
  onPaste(event: any) {
    let clipboardData = event.clipboardData || window.Clipboard;
    let pastedText = clipboardData.getData('text').replaceAll('-', '');
    this.listInput = this.listInput.map((item: any) => {
      const a = item.id.slice(2)
      item.value = pastedText.substring((+a - 1) * 4, (+a) * 4)
      return {
        ...item
      }
    }
    )
  }

  handleCorrectCaptcha(event: any) {
    this.token = event;
    this.textError = '';

  }
  close() {
    this.modalCommonService.closeModal(this.idOpenBook)
  }

  // getListCourse() {
  //   this.listChangeService.getListCourse().subscribe((res: any) => {
  //     this.listCourse = res?.data;
  //   });
  // }

}
