<div class="course">
    @for (course of listCourse; track $index) {
    <div class="course-item">
        <div class="icon">
           <img [src]="course.thumbnail" alt="">
        </div>
        <div class="name">{{course?.name}}</div>
        <div class="bottom-item">
            @if (course?.isCurrent && course?.isAccess) {
            <div class="button continues" (click)="OpenUnlockBooks('continues',course)">
                Đang học 
            </div>
            }
            @if (!course?.isCurrent && course?.isAccess) {
            <div class="button start" (click)="OpenUnlockBooks('start', course)">
                Bắt đầu <img src="assets/home/course/icon-arrow-start.svg" alt="">
            </div>
            }
            @if (!course?.isAccess) {
            <div class="info">
                <div class="left">Nhập mã sách để mở khóa học</div>
                <div class="button buy" (click)="OpenUnlockBooks('buy', course)">
                    Mở khóa <img src="assets/home/course/icon-unlock.svg" alt="">
                </div>
            </div>
            }
        </div>
    </div>
    }
</div>