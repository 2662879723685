export const LEVEL = [

    {
        scoreConfig : 200,
        criteria: [
            {
                score: 80,
                maxSocre:140,
                level: 1,
            },
            {
                score: 140,
                maxSocre:200,
                level: 2,
            },
        ]
    },  
    {
        scoreConfig:300,
        criteria: [
            {
                score: 120,
                maxSocre: 150,
                level: 3,
            },
            {
                score: 150,
                maxSocre: 190,
                level: 4,
            },
            {
                score: 190,
                maxSocre: 230,
                level: 5,
            },
            {
                score: 230,
                maxSocre: 300,
                level: 6,
            },
        ]
    },
]