<div class="container-popup">
    <div class="content-popup">
        <div class="icon">
            <img src="/assets/common/streak-icon.svg" alt="">
        </div>
        <div class="total-streak">
            {{totalStreak}} ngày streak
        </div>
        <div class="des">
            Tuyệt vời, bạn đã hoàn thành mục tiêu hôm nay!
        </div>

        <div class="week">
            <div class="day" *ngFor="let day of dayWithTimeStamp" [class.active-day]="day.isDone">{{day.name}}</div>
        </div>
    </div>
</div>