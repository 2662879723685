import { UploadImagePopupComponent } from './upload-image-popup.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvatarModule } from 'ngx-avatars';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  imports: [
    ImageCropperModule,
    CommonModule,
    AvatarModule,
  ],
  declarations: [
    UploadImagePopupComponent
  ],
  exports: [
    UploadImagePopupComponent
  ]
})
export class UploadImagePopupModule { }