import { Component, OnInit } from '@angular/core';
import { ReportPieComponent } from './report-pie/report-pie.component';
import { LessonService } from '../../../../components/learn/lesson.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-report-learn',
  standalone: true,
  imports: [ReportPieComponent, CommonModule],
  templateUrl: './report-learn.component.html',
  styleUrl: './report-learn.component.scss'
})
export class ReportLearnComponent implements OnInit {
  dayWithTimeStamp: any = [];
  timeStampLastDate: any;
  totalStreak: any;
  type = 'learn'
  constructor(
    private lessonService: LessonService
  ) {

  }
  ngOnInit() { 
    this.getDataSteak();
    
  }
  getDataSteak() {
    this.lessonService.getStreak(this.type).subscribe((res: any) => {
      if(res) {
        this.totalStreak = res.totalDay;
        this.timeStampLastDate = this.getTimeStampWithDay(res.lastDate.toString());
        this.getDayOfWeek(new Date());
      }
    })
  }
  getTimeStampWithDay(dayConvert: any) {
    const year = dayConvert.substring(0, 4);
    const month = parseInt(dayConvert.substring(4, 6)) - 1;
    const day= dayConvert.substring(6, 8);
   return new Date(year, month, day).getTime();
  }
  getDayOfWeek(dateWeek: any) {
    this.dayWithTimeStamp = [];
    const now = new Date(dateWeek);
    const day = dateWeek.getDay();
    const diff = dateWeek.getDate() - day + (day === 0 ? -6 : 1);
    for (let i = 0; i < 7; i++) {
      dateWeek = new Date(now);
      dateWeek.setDate(+diff + i);

      if (i + 2 == 8) {
        dateWeek.setHours(0, 0, 0, 0);
        this.dayWithTimeStamp.push({
          name: "CN",
          date: dateWeek.getTime(),
          index: i,
        });
      } else {
        dateWeek.setHours(0, 0, 0, 0);
        this.dayWithTimeStamp.push({
          name: "T" + (i + 2),
          date: dateWeek.getTime(),
          index: i,
        });
      }

    }
    console.log(this.timeStampLastDate);
    
    if (this.timeStampLastDate) {
      console.log(this.dayWithTimeStamp);
      
      let total = this.totalStreak
      const indexLastDay = this.dayWithTimeStamp.findIndex((day: any) => day.date === this.timeStampLastDate);
      console.log(indexLastDay);
      
      if(indexLastDay > -1) {
        this.dayWithTimeStamp = this.dayWithTimeStamp.map((day: any, index: any) => {
          let isDone = false;
          if(index === indexLastDay ) {
            total = total - 1
            isDone = true
          } if(index === indexLastDay - total + 1 && this.totalStreak >= 2 && total > 0) {
            isDone = true;
            total = total - 1

          }
          return {
            ...day,
            isDone
          }
        })
        console.log(this.dayWithTimeStamp);
        
      }
      
    }
  }

}
