import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RightSideService } from '../right-side.service';
import { LEVEL } from '../level';
import { LessonService } from '../../../../components/learn/lesson.service';
@Component({
  selector: 'app-quick-report',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './quick-report.component.html',
  styleUrl: './quick-report.component.scss'
})
export class QuickReportComponent implements OnInit {
  @Input() showStreak = true;
  listDay = [
    {
      name: 'T2',
    },
    {
      name: 'T3',
    },
    {
      name: 'T4',
    },
    {
      name: 'T5',
    },
    {
      name: 'T6',
    },
    {
      name: 'T7',
    },
    {
      name: 'CN',
    },
  ];
  level: any;
  dataFullTest: any;
  dayWithTimeStamp: any = [];
  dataDay: any = [];
  currentLesson: any;
  dataConfirm: any;
  totalStreak: any = 0;
  timeStampLastDate: any;
  type = 'exam'
  constructor(
    private rightSideService: RightSideService,
    private lessonService: LessonService,
  ) {

  }
  ngOnInit(): void {
    this.getReport();
  }
  getReport() {
    let type = 0;
    const target = localStorage.getItem('current-target');
    if (target) {
      type = +target / 100
    }
    this.rightSideService.getReport(type).subscribe((res: any) => {
      if (res?.success && res?.data) {
        this.dataFullTest = res?.data.dataFullTest;
        // this.dataDay = res?.data?.dataByDay;
        // this.getDayOfWeek(new Date())
        this.getDataSteak();
        const currentLength = LEVEL.find(l => this.dataFullTest.scoreConfig === l.scoreConfig)
        currentLength?.criteria.some(l => {
          if (this.dataFullTest.totalScore > l.score && l.maxSocre >= this.dataFullTest.totalScore) {
            this.level = l.level;
            return true
          }
          else {
            this.level = '0'
            return false
          }
        })
      }
    });
  }
  getDataSteak() {
    this.lessonService.getStreak(this.type).subscribe((res: any) => {
      if (res) {
        this.totalStreak = res.totalDay;
        this.timeStampLastDate = this.getTimeStampWithDay(res.lastDate.toString());
        this.getDayOfWeek(new Date());
      }
    })
  }
  getTimeStampWithDay(dayConvert: any) {
    const year = dayConvert.substring(0, 4);
    const month = parseInt(dayConvert.substring(4, 6)) - 1;
    const day = dayConvert.substring(6, 8);
    return new Date(year, month, day).getTime();
  }
  getDayOfWeek(dateWeek: any) {
    this.dayWithTimeStamp = [];
    const now = new Date(dateWeek);
    const day = dateWeek.getDay();
    const diff = dateWeek.getDate() - day + (day === 0 ? -6 : 1);
    for (let i = 0; i < 7; i++) {
      dateWeek = new Date(now);
      dateWeek.setDate(+diff + i);

      if (i + 2 == 8) {
        dateWeek.setHours(0, 0, 0, 0);
        this.dayWithTimeStamp.push({
          name: "CN",
          date: dateWeek.getTime(),
          index: i,
        });
      } else {
        dateWeek.setHours(0, 0, 0, 0);
        this.dayWithTimeStamp.push({
          name: "T" + (i + 2),
          date: dateWeek.getTime(),
          index: i,
        });
      }

    }
    if (this.timeStampLastDate) {
      let total = this.totalStreak
      const indexLastDay = this.dayWithTimeStamp.findIndex((day: any) => day.date === this.timeStampLastDate);
      if (indexLastDay > -1) {
        this.dayWithTimeStamp = this.dayWithTimeStamp.map((day: any, index: any) => {
          let isDone = false;
          if (index === indexLastDay) {
            total = total - 1
            isDone = true
          } if (index < indexLastDay && this.totalStreak > 2 && total > 0) {
            isDone = true;
            total = total - 1

          }
          return {
            ...day,
            isDone
          }
        })
      }
    }
  }
}
