import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-report-pie',
  standalone: true,
  templateUrl: './report-pie.component.html',
  styleUrls: ['./report-pie.component.scss']
})
export class ReportPieComponent implements OnInit, AfterViewInit {
  dataPieChart: any;
  constructor(private activatedRoute: ActivatedRoute,
    private zone: NgZone) {

  }
  ngOnInit(): void {
    this.activatedRoute?.data.subscribe(data => {
      console.log(data);
      
      this.dataPieChart = data.dataChart[0].data.pieChart;      

    });
  }
  ngAfterViewInit(): void {
    if( !this.dataPieChart?.totalLesson) {
      return;
    }
    this.zone.runOutsideAngular(() => {
      Promise.all([
        import('@amcharts/amcharts4/core'),
        import('@amcharts/amcharts4/charts'),
        import('@amcharts/amcharts4/themes/animated')
      ]).then(modules => {
        const am4core = modules[0];
        const am4charts = modules[1];
        am4core.addLicense('CH194152149');

        const chart = am4core.create('pie-chart', am4charts.PieChart);
        chart.radius = am4core.percent(70)

        // Add data
        chart.data = [
          {
            name: 'Hoàn thành',
            value: this.dataPieChart?.totalLessonFinish,
            'color': am4core.color('#FF5F7A'),

          },
          {
            name: 'Chưa hoàn thành',
            value: this.dataPieChart?.totalLesson - this.dataPieChart?.totalLessonFinish,
            'color': am4core.color('#F1F4FC'),
            

          }
        ];
        const pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "name";
        pieSeries.slices.template.propertyFields.fill = 'color';
        pieSeries.labels.template.text = "[#FF5F7A font-size: 12px font-weight:600]{value} bài ({value.percent.formatNumber('#.0')}%)";
        // pieSeries.label.text = '{name}'
        pieSeries.legendSettings.valueText = ' ';
        pieSeries.legendSettings.labelText = '[#FF5F7A font-size: 14px font-weight:600]{name}';
        pieSeries.alignLabels = false;
        chart.legend = new am4charts.Legend();
        chart.legend.useDefaultMarker = true;
        chart.legend.labels.template.truncate = false;
        chart.legend.labels.template.wrap = true;

        chart.legend.maxWidth = undefined || 0;
      }
      )

    })
  }
}
