@if (currentLesson) {
<div class="continue" (click)="continuesLesson()">
    <div class="content">
        <div class="img">
            <img src="assets/learn/nav-lesson/learn-ctn.svg" alt="">
        </div>
        <div class="title">
            <div class="text-btn">
                Tiếp tục học
            </div>
            <div class="name">
                {{this.currentLesson.lessonName}}
            </div>
        </div>
    </div>
    <div class="play ">
        <img src="assets/learn/nav-lesson/arrow-right.svg" alt="">
    </div>
</div>
}